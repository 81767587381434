import { useContext, useEffect, useState } from "react";
import {
  Color,
  Confirm,
  ConfirmContext,
} from "../context/ConfirmContextProvider";

interface UseConfirmReturn extends Confirm {
  isConfirmed: (prompt: string, color?: Color) => Promise<boolean>;
}

const useConfirm = (): UseConfirmReturn => {
  const [confirm, setConfirm] = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (prompt: string, color?: Color): Promise<boolean> => {
    const promise = new Promise<boolean>((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: () => resolve(true),
        cancel: () => reject(false),
        color: color ?? Color.warning,
      });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm({
        prompt: "",
        proceed: () => {},
        cancel: () => {},
        isOpen: false,
        color: Color.warning,
      });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;
