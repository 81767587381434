import {
    BookmarkIcon,
    CheckIcon,
    MinusIcon,
    PlusIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { CATEGORIES, getCategoryById } from "categories";
import CategoriesDropdown from "components/CategoriesDropdown";
import Drawer from "components/Drawer";
import TrashIcon from "components/icons/TrashIcon";
import {
    deleteItemFromDB,
    deleteSavedItemFromDB,
    updateItemInDB,
    updateSavedItemInDB,
    voteForCategory,
} from "db-utils";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import { useEffect, useRef, useState } from "react";
import { Item, LIST_TYPES } from "types";
import {
    formatPhoneNumber,
    getUnitText,
    isPremium,
    isPremiumList,
} from "utils";
import { unitsConfig } from "../../constants";
import SelectCategoryPopup from "./SelectCategoryPopup";

export default function EditItemDrawer({
    open,
    setOpen,
    onClose,
    onOpen,
    text,
    quantity,
    unit,
    categoryId,
    comments,
    checked,
    missing,
    addedBy,
    addedAt,
    saved,
    isSavedItemsMode = false,
}: Item & {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    onOpen: () => void;
    isSavedItemsMode?: boolean;
}) {
    const { listData, loading, error } = useList();
    const { user } = useUser();
    const [values, setValues] = useState({
        text,
        quantity,
        unit,
        categoryId,
        comments,
        saved,
        checked,
        missing,
    });
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setValues({
            text,
            quantity,
            unit,
            categoryId,
            comments,
            saved,
            checked,
            missing,
        });
    }, [text, quantity, unit, categoryId, comments, saved, checked, missing]);

    useEffect(() => {
        const originalItem = {
            text,
            quantity,
            unit,
            categoryId,
            comments,
            saved,
            checked,
            missing,
        };
        for (const [key, value] of Object.entries(values)) {
            if (value !== originalItem[key as keyof typeof originalItem]) {
                setIsDirty(true);
                break;
            } else {
                setIsDirty(false);
            }
        }
    }, [
        values,
        text,
        quantity,
        unit,
        categoryId,
        comments,
        saved,
        checked,
        missing,
    ]);

    const nameInputRef = useRef<HTMLInputElement>(null);

    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();
    // const isPremiumUser = isPremium({
    //     type: user?.userData?.accountType,
    // });
    // const isListPremium = isPremiumList({ type: listData?.listAccountType });
    const blockPremiumFeatures = false; //!isPremiumUser && !isListPremium;

    if (!listData) return null;

    const PremiumWallAlert = () =>
        isAlerted(
            "רק משתמשי Plus ⚡ יכולים להשתמש בפי'צר הזה. מוזמנים להצטרף אליהם"
        );

    const changeQuantity = async ({ type }: { type: "inc" | "dec" }) => {
        // if (blockPremiumFeatures) {
        //     await PremiumWallAlert();
        //     return;
        // }
        const newQuantity = (values.quantity ?? 0) + (type === "inc" ? 1 : -1);
        setValues({
            ...values,
            quantity: newQuantity <= 0 ? null : newQuantity,
        });
    };

    const onSubmit = async () => {
        if (!listData || !listData.items) {
            await isAlerted("מצטערים, קרתה תקלה");
            return;
        }

        if (values.text.trim() === "") {
            await isAlerted("שם הפריט לא יכול להיות ריק");
            return;
        }

        const listWithoutCurrentItem = listData[
            isSavedItemsMode ? "savedItems" : "items"
        ].filter((item) => item.text !== text);

        const itemName = values.text;
        if (listWithoutCurrentItem.map((i) => i.text).includes(itemName)) {
            await isAlerted("מצטערים, כבר קיים פריט עם השם הזה");
            return;
        }

        const updates = {
            text: values.text.trim(),
            quantity: values.quantity,
            unit: values.unit,
            categoryId: values.categoryId,
            comments: values.comments ? values.comments.trim() : null,
            saved: values.saved,
            checked: values.checked,
            missing: values.missing,
        };
        if (isSavedItemsMode) {
            await updateSavedItemInDB({
                itemId: text,
                listId: listData.id,
                existingSavedItems: listData.savedItems,
                updates,
            });
        } else {
            await updateItemInDB({
                itemId: text,
                listId: listData.id,
                existingItems: listData.items,
                existingSavedItems: listData.savedItems,
                updates,
            });
        }
        // window.scrollTo(0, 0);
        onClose();
    };

    const onDelete = async () => {
        if (await isConfirmed("בטוח להמשיך עם המחיקה?")) {
            if (isSavedItemsMode) {
                await deleteSavedItemFromDB({
                    itemId: text,
                    listId: listData.id,
                    existingSavedItems: listData.savedItems,
                    existingItems: listData.items,
                });
            } else {
                await deleteItemFromDB({
                    itemId: text,
                    listId: listData.id,
                    existingItems: listData.items,
                });
            }
            setOpen(false);
        }
    };

    const onAddToSaved = async () => {
        // if (blockPremiumFeatures) {
        //     await PremiumWallAlert();
        //     return;
        // }
        setValues({
            ...values,
            saved: !values.saved,
        });
    };

    const onCheck = async () => {
        // if (blockPremiumFeatures) {
        //     await PremiumWallAlert();
        //     return;
        // }
        setValues({
            ...values,
            checked: !values.checked,
            missing: false,
        });
    };

    const onMissing = async () => {
        if (blockPremiumFeatures) {
            await PremiumWallAlert();
            return;
        }
        setValues({
            ...values,
            missing: !values.missing,
            checked: false,
        });
    };

    const addedAtDate = addedAt.toDate().toLocaleDateString("he-IL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });

    const { type } = listData;
    const isPrimaryList = type === LIST_TYPES.primary;

    const unitOptions = [
        values.unit ? (
            <option key={values.unit} value={values.unit}>
                {getUnitText(values.unit)}
            </option>
        ) : (
            <option key="none" value={undefined} className="text-gray-400">
                יחידה
            </option>
        ),
    ].concat(
        Object.entries(unitsConfig)
            .filter(([u, _]) => u !== values.unit)
            .map(([key, value]) => (
                <option key={key} value={key}>
                    {value.displayText}
                </option>
            ))
    );

    return (
        <Drawer
            className="flex flex-col justify-between pb-5 no-select"
            open={open}
            setOpen={setOpen}
            onClose={() => {
                if (isDirty) {
                    onSubmit();
                }
                setOpen(false);
            }}
            onOpen={onOpen}
            disableSwipeToOpen
        >
            <form
                className="text-xl flex flex-col h-full justify-between pt-2 md:w-[500px] lg:mx-auto"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isDirty) {
                        onSubmit();
                    }
                }}
            >
                <div className="text-xl flex flex-col gap-7 relative">
                    <div className="flex absolute -top-10 -right-2 w-full">
                        <button
                            className="rounded-xl shrink-0 w-10 h-10 flex items-center justify-center"
                            onClick={() => setOpen(false)}
                        >
                            <XMarkIcon className="w-7 h-7 shrink-0 text-slate-600" />
                        </button>
                    </div>
                    {isSavedItemsMode ? (
                        <div className="text-lg font-semibold">
                            עריכה ב"שמורים"
                        </div>
                    ) : null}
                    <div
                        className={`text-xl flex flex-col ${
                            values.comments ? "gap-3" : "gap-3"
                        } bg-blue-50/50 rounded-xl p-4`}
                    >
                        <div className="flex justify-between items-center gap-3">
                            {/* <button
                                className="rounded-xl shrink-0 w-10 h-10 flex items-center justify-center"
                                onClick={() => setOpen(false)}
                            >
                                <ArrowRightIcon className="w-6 h-6 shrink-0 text-slate-500" />
                            </button> */}
                            <input
                                ref={nameInputRef}
                                type="text"
                                value={values.text ?? ""}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        text: e.target.value,
                                    });
                                }}
                                placeholder="שם הפריט"
                                className={`w-full h-12 px-2 rounded-xl text-2xl border border-gray-100 placeholder-gray-400 focus:outline-none ${
                                    values.text
                                        ? "text-slate-800"
                                        : "text-gray-400"
                                } focus:border-slate-900 font-medium`}
                            />
                            <SelectCategoryPopup
                                show={isPrimaryList}
                                button={
                                    <div className="bg-slate-300/20 rounded-full w-10 h-10 text-2xl flex items-center justify-center transition-all">
                                        {
                                            getCategoryById({
                                                id: values.categoryId,
                                            }).icon
                                        }
                                    </div>
                                }
                                categoryOptions={Object.values(CATEGORIES)}
                                onSelect={({ categoryId }) => {
                                    setValues({
                                        ...values,
                                        categoryId,
                                    });
                                    voteForCategory({
                                        categoryId,
                                        itemId: values.text,
                                    });
                                }}
                            />
                            {/* {isPrimaryList ? (
                                <CategoriesDropdown
                                    button={
                                        <div className="bg-slate-300/20 rounded-full w-10 h-10 text-2xl flex items-center justify-center transition-all">
                                            {
                                                getCategoryById({
                                                    id: values.categoryId,
                                                }).icon
                                            }
                                        </div>
                                    }
                                    onSelect={(categoryId) => {
                                        setValues({
                                            ...values,
                                            categoryId,
                                        });
                                        voteForCategory({
                                            categoryId,
                                            itemId: values.text,
                                        });
                                    }}
                                />
                            ) : null} */}
                        </div>
                        <div className="flex flex-col gap-1">
                            {/* <div className="flex flex-col gap-1 w-[calc(100%_-_6.5rem)] mx-auto"> */}
                            {/* {values.comments ? (
                                <label
                                    htmlFor="comments"
                                    className="text-sm h-4"
                                >
                                    הערה:
                                </label>
                            ) : null} */}
                            <input
                                type="text"
                                disabled={blockPremiumFeatures}
                                id="comments"
                                value={values.comments ?? ""}
                                onChange={(e) =>
                                    setValues({
                                        ...values,
                                        comments: e.target.value,
                                    })
                                }
                                placeholder={
                                    blockPremiumFeatures
                                        ? "הוספת הערה (זמין בגרסת Plus)"
                                        : "כאן כותבים הערה"
                                }
                                className="h-12 w-full px-2 rounded-xl border border-gray-100 placeholder-gray-400 focus:outline-none text-slate-700 text-lg focus:border-slate-900"
                            />
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="flex justify-between items-center gap-5">
                        <div className="flex gap-3 justify-between w-1/3 text-slate-500">
                            <button
                                disabled={Boolean(
                                    values.quantity && values.quantity > 100
                                )}
                                onClick={() => changeQuantity({ type: "inc" })}
                                type="button"
                                className="flex items-center justify-center h-12 w-12 rounded-full text-blue-400 disabled:text-slate-200"
                                // className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 disabled:text-slate-200 p-2"
                            >
                                <PlusIcon className="w-10 h-10" />
                            </button>
                            <button
                                type="button"
                                disabled={Boolean(
                                    !values.quantity
                                    // !values.quantity || values.quantity <= 1
                                )}
                                onClick={() => changeQuantity({ type: "dec" })}
                                className="flex items-center justify-center h-12 w-12 rounded-full text-blue-300 disabled:text-slate-200"
                            >
                                <MinusIcon className="w-10 h-10" />
                            </button>
                        </div>
                        <input
                            type="number"
                            value={values.quantity ?? ""}
                            onChange={async (e) => {
                                if (blockPremiumFeatures) {
                                    await PremiumWallAlert();
                                    return;
                                }
                                const newQuantity = Number(e.target.value);
                                setValues({
                                    ...values,
                                    quantity:
                                        newQuantity <= 0 ? null : newQuantity,
                                });
                            }}
                            className="w-1/3 h-10 text-center px-2 rounded-xl border border-gray-300 placeholder-gray-400 focus:outline-none text-slate-800 focus:border-slate-900"
                            placeholder="כמות"
                        />
                        <select
                            value={values.unit ?? ""}
                            onChange={async (e) => {
                                // if (blockPremiumFeatures) {
                                //   await PremiumWallAlert();
                                //   return;
                                // }
                                setValues({
                                    ...values,
                                    unit: e.target.value ?? null,
                                });
                            }}
                            className="h-10 w-1/3 px-2  text-center rounded-xl border bg-white border-gray-300 placeholder:text-gray-400 focus:outline-none text-slate-800 focus:border-slate-900"
                            placeholder="יחידה"
                        >
                            {unitOptions.map((option) => option)}
                        </select>
                    </div>

                    <div
                        className={`${
                            isSavedItemsMode ? "" : "grid grid-cols-3"
                        } gap-5 text-center items-center justify-center w-full text-lg border-t border-gray-300 pt-8 mt-2`}
                    >
                        {isSavedItemsMode ? null : (
                            <button
                                onClick={onCheck}
                                type="button"
                                className={`justify-center w-full items-center flex gap-2 rounded-full h-10 border text-slate-500 px-2 py-1 ${
                                    values.checked
                                        ? "bg-green-100/30 border-green-500"
                                        : "border-slate-400"
                                }`}
                            >
                                <span>{values.checked ? "בטל" : "סמן"}</span>
                                <CheckIcon className="w-5 h-5 text-green-500" />
                            </button>
                        )}
                        {isSavedItemsMode ? null : (
                            <button
                                onClick={onMissing}
                                type="button"
                                className={`justify-center w-full items-center flex gap-2 rounded-full h-10 border text-slate-500 px-2 py-1 ${
                                    values.missing
                                        ? "bg-slate-300 border-slate-400"
                                        : "border-slate-400"
                                }`}
                            >
                                <span>{values.missing ? "בטל" : "חסר"}</span>
                                <span className="w-5 h-5 text-base">😢</span>
                            </button>
                        )}
                        {isSavedItemsMode ? null : (
                            <button
                                onClick={onAddToSaved}
                                type="button"
                                className={`justify-center w-full items-center flex gap-2 rounded-full h-10 border text-slate-500 px-2 py-1 ${
                                    values.saved
                                        ? "bg-yellow-50/50 border-yellow-400"
                                        : "border-slate-400"
                                }`}
                            >
                                <span>{values.saved ? "בטל" : "שמור"}</span>
                                <BookmarkIcon className="w-5 h-5 text-yellow-400" />
                            </button>
                        )}
                        <button
                            onClick={onDelete}
                            type="button"
                            className="justify-center w-full items-center flex gap-2 rounded-full h-10 border border-slate-400 text-slate-500 px-2 py-1"
                        >
                            <span>
                                {isSavedItemsMode ? "הסר מהשמורים" : "מחק"}
                            </span>
                            <TrashIcon className="w-5 h-5 text-red-500/70" />
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-5">
                    {addedBy ? (
                        <div className="text-sm text-center">
                            נוסף ע"י{" "}
                            <span className="font-bold">
                                {formatPhoneNumber({ phoneNumber: addedBy })}
                            </span>{" "}
                            בתאריך <span className="">{addedAtDate}</span>
                        </div>
                    ) : null}
                </div>
            </form>
        </Drawer>
    );
}
