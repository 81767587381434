import { BookmarkIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { deleteItemFromDB, updateItemInDB, voteForCategory } from "db-utils";
import useAlert from "hooks/useAlert";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useLongPress } from "use-long-press";
import { getUnitShortText } from "utils";
import { CATEGORIES, getCategoryById } from "../../categories";
import TrashIcon from "../../components/icons/TrashIcon";
import { LONG_PRESS_VIBRATE } from "../../constants";
import { Item, LIST_TYPES } from "../../types";
import SelectCategoryPopup from "./SelectCategoryPopup";

export default function EditListItem({
    text,
    checked,
    categoryId,
    quantity,
    unit,
    comments,
    saved,
    addedBy,
    onItemSelect,
    onItemLongPress,
    isQuantitiesMode = false,
}: Item & {
    onItemSelect: () => void;
    onItemLongPress?: () => void;
    isQuantitiesMode?: boolean;
}) {
    const { listData } = useList();
    const { user } = useUser();
    const { isAlerted } = useAlert();
    const [updating, setUpdating] = useState(false);

    const onLongPress = useLongPress(
        () => {
            if (navigator.vibrate) {
                navigator.vibrate(LONG_PRESS_VIBRATE);
            }
            if (onItemLongPress) {
                onItemLongPress();
            }
        },
        {
            threshold: 500,
        }
    );

    if (!listData) return null;

    const changeQuantity = async ({ type }: { type: "inc" | "dec" }) => {
        // if (blockPremiumFeatures) {
        //     await PremiumWallAlert();
        //     return;
        // }
        setUpdating(true);
        const newQuantity = (quantity ?? 0) + (type === "inc" ? 1 : -1);

        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                quantity: newQuantity,
            },
        });
        setUpdating(false);
    };

    const updatedIsSavedItem = async () => {
        // const isUserPremium = isPremium({
        //     type: user?.userData?.accountType,
        // });

        // if (!isUserPremium) {
        //     isAlerted("רק משתמשי Plus ⚡ יכולים לשמור פריטים");
        //     return;
        // }
        setUpdating(true);
        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                saved: !saved,
            },
        });
        setUpdating(false);
    };

    const onCategorySelect = async ({ categoryId }: { categoryId: string }) => {
        setUpdating(true);
        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                categoryId,
            },
        });
        voteForCategory({
            categoryId,
            itemId: text,
        });
        setUpdating(false);
    };

    const onDeleteItem = async () => {
        setUpdating(true);
        await deleteItemFromDB({
            itemId: text,
            listId: listData.id,
            existingItems: listData.items,
        });
        setUpdating(false);
    };

    const { type } = listData;
    const isPrimaryList = type === LIST_TYPES.primary;
    const unitText = getUnitShortText(unit).substring(
        0,
        getUnitShortText(unit).includes('"') ? 3 : 2
    );
    const isQuantitnyTextLong =
        quantity && (quantity.toString().length > 2 || unitText);

    return (
        <>
            <li
                className={`flex cursor-pointer items-center justify-between h-[3.95rem] py-3 pr-1.5 w-full gap-1`}
            >
                <button
                    className={`${
                        isPrimaryList
                            ? isQuantitnyTextLong
                                ? "w-[45%]"
                                : "w-[50%]"
                            : "w-[60%]"
                    } flex gap-0 items-center h-full no-select`}
                    {...onLongPress()}
                    onClick={onItemSelect}
                >
                    <div
                        className={`inline text-start cursor-pointer break-keep items-center max-w-[80vw] whitespace-nowrap w-66 h-full overflow-auto transition-all ${
                            // className={`inline text-start cursor-pointer underline decoration-1 break-keep items-center max-w-[80vw] whitespace-nowrap w-66 h-full overflow-auto transition-all ${
                            checked ? "text-slate-800" : ""
                        }`}
                    >
                        {text}{" "}
                        {comments ? (
                            <span className="text-lg">
                                (<span className="italic">{comments}</span>)
                            </span>
                        ) : null}
                    </div>
                </button>

                <div className={`flex items-center gap-3`}>
                    {quantity ? (
                        <div
                            className={`text-center text-xl text-slate-500 font-poppins
                        ${isQuantitnyTextLong ? "w-16" : "w-8"}
                        `}
                        >
                            {quantity}
                            {unit ? (
                                <span className="text-base text-slate-400 font-assistant pr-0.5">
                                    {unitText}
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                    {isQuantitiesMode ? (
                        <div className="flex items-center gap-2.5">
                            <button
                                disabled={updating}
                                onClick={() => changeQuantity({ type: "inc" })}
                                className="w-12 h-12 rounded-full bg-blue-200 p-2 flex items-center justify-center"
                            >
                                <PlusIcon className="w-7 h-7" />
                            </button>
                            <button
                                disabled={updating || !quantity}
                                onClick={() => changeQuantity({ type: "dec" })}
                                className="w-12 h-12 rounded-full bg-blue-100 disabled:bg-blue-50/50 p-2 flex items-center justify-center"
                            >
                                <MinusIcon className="w-7 h-7" />
                            </button>
                        </div>
                    ) : (
                        <>
                            <button
                                onClick={updatedIsSavedItem}
                                type="button"
                                className={`w-7 h-7 font-thin`}
                            >
                                {saved ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-full h-7 text-amber-300"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <BookmarkIcon className="text-slate-600 h-7 w-full" />
                                )}
                            </button>

                            <SelectCategoryPopup
                                show={isPrimaryList}
                                button={
                                    <div
                                        className={`m-auto rounded-full bg-slate-50 text-xl w-8 h-8 p-1  flex items-center justify-center`}
                                    >
                                        {
                                            getCategoryById({
                                                id: categoryId,
                                            }).icon
                                        }
                                    </div>
                                }
                                categoryOptions={Object.values(CATEGORIES)}
                                onSelect={onCategorySelect}
                            />
                            <button
                                type="button"
                                onClick={onDeleteItem}
                                className={`text-red-500/70 w-7 h-7`}
                            >
                                <TrashIcon className="" />
                            </button>
                        </>
                    )}
                </div>
            </li>
        </>
    );
}
