import React from "react";

interface MeltingClockIconProps {
  className?: string;
}

const MeltingClockIcon: React.FC<MeltingClockIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 48"
      className={`fill-current ${className || ""}`}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="_1" data-name="1">
          <path
            className="fill-current"
            d="M19 20a1 1 0 0 1-.24 0l-8-2 .48-2 7.45 1.86 7.6-7.6 1.42 1.42-8 8A1 1 0 0 1 19 20zM4 18h2v2H4zM18 32h2v2h-2zM18 4h2v2h-2zM32 18h2v2h-2zM30 11h2v2h-2zM30 25h2v2h-2zM6 11h2v2H6zM6 25h2v2H6zM25 6h2v2h-2zM11 6h2v2h-2zM11 30h2v2h-2z"
          />
          <path
            className="fill-current"
            d="M19 48a5 5 0 0 1-5-5v-4a3 3 0 0 0-6 0v2a4 4 0 0 1-8 0V19a19 19 0 0 1 38 0v17a5 5 0 0 1-10 0v-4a2 2 0 0 0-4 0v11a5 5 0 0 1-5 5zm-8-14a5 5 0 0 1 5 5v4a3 3 0 0 0 6 0V32a4 4 0 0 1 8 0v4a3 3 0 0 0 6 0V19a17 17 0 0 0-34 0v22a2 2 0 0 0 4 0v-2a5 5 0 0 1 5-5z"
          />
        </g>
      </g>
    </svg>
  );
};

export default MeltingClockIcon;
