import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Disclosure, Menu, Switch, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { getCategoryById } from "categories";
import Link from "components/Link";
import { ListNavbar } from "components/Navbar";
import ListCXT from "context/ListContext";
import { activateSplitMode, updatePartialList } from "db-utils";
import useAlert from "hooks/useAlert";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useUser from "hooks/useUser";
import { Fragment, useState } from "react";
import { formatPhoneNumber, isPremium } from "utils";

const part1bg = "bg-emerald-200/75";
const part2bg = "bg-sky-200/75";
const LeftRightIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5 group-disabled:opacity-40"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
        />
    </svg>
);

export default function SplitPage() {
    const { listId } = useListIdFromParams();
    const { listData } = useListData({ listId });
    const { user } = useUser();
    const [switchAssigneesIcon, setSwitchAssigneesIcon] =
        useState(LeftRightIcon);

    const { isAlerted } = useAlert();

    const { items, categoriesSortOrder, splitMode, participants } =
        listData ?? {};

    let { partialListsConfig } = listData ?? {};

    if (!listData || !items) return null;

    if (!categoriesSortOrder)
        return (
            <div className="flex flex-col items-center justify-center gap-8 mt-20">
                <h1 className="text-2xl text-center">
                    כרגע ניתן לפצל רק את רשימת הקניות הראשית
                </h1>
                <Link
                    to={`/list/${listId}`}
                    className={`px-3 py-2 w-56 text-xl block text-center rounded-lg bg-green-500 text-white hover:bg-green-600`}
                >
                    חזרה לרשימה
                </Link>
            </div>
        );

    const toggleSplitMode = async () => {
        const isUserPremium = isPremium({
            type: user?.userData?.accountType,
        });
        if (!isUserPremium) {
            await isAlerted("פיצול הרשימה זמין רק בגרסת הפלוס");
            return;
        }

        await activateSplitMode({
            listId,
            currentSplitMode: Boolean(splitMode),
        });
    };

    partialListsConfig = partialListsConfig ?? [
        {
            categories: [],
            assignees: [],
        },
        {
            categories: [],
            assignees: [],
        },
    ];

    const categoriesPart1 = Object.values(partialListsConfig[0].categories)
        .map((categoryId) => {
            return {
                id: categoryId,
                inPart: 1 as 1,
                numOfItems: Object.values(items).filter(
                    (item) => item.categoryId === categoryId
                ).length,
            };
        })
        .sort((a, b) => {
            return b.numOfItems - a.numOfItems;
        });

    const categoriesPart2 = Object.values(partialListsConfig[1].categories)
        .map((categoryId) => {
            return {
                id: categoryId,
                inPart: 2 as 2,
                numOfItems: Object.values(items).filter(
                    (item) => item.categoryId === categoryId
                ).length,
            };
        })
        .sort((a, b) => {
            return b.numOfItems - a.numOfItems;
        });

    const toggleParts = async ({
        categoryId,
        inPart1,
    }: {
        categoryId: string;
        inPart1: boolean;
    }) => {
        let newPart1Cats: Array<string> = [],
            newPart2Cats: Array<string> = [];
        if (inPart1) {
            newPart1Cats = categoriesPart1
                .filter((cat) => cat.id !== categoryId)
                .map((cat) => cat.id);
            newPart2Cats = [categoryId].concat(
                categoriesPart2.map((cat) => cat.id)
            );
        } else {
            newPart1Cats = [categoryId].concat(
                categoriesPart1.map((cat) => cat.id)
            );
            newPart2Cats = categoriesPart2
                .filter((cat) => cat.id !== categoryId)
                .map((cat) => cat.id);
        }

        const updates = {
            partialListsConfig: [
                {
                    categories: newPart1Cats,
                    assignees: partialListsConfig[0].assignees,
                },
                {
                    categories: newPart2Cats,
                    assignees: partialListsConfig[1].assignees,
                },
            ],
        };

        await updatePartialList({
            listId,
            updates,
        });
    };

    const part1Assignees = partialListsConfig[0].assignees;
    const part2Assignees = partialListsConfig[1].assignees;

    const onToggleAssignee = async ({
        assigneeId,
        part,
    }: {
        assigneeId: string;
        part: 1 | 2;
    }) => {
        let newPart1Assignees: Array<string> = [],
            newPart2Assignees: Array<string> = [];
        if (part === 1) {
            newPart1Assignees = part1Assignees.filter(
                (assignee) => assignee !== assigneeId
            );
            newPart2Assignees = [assigneeId].concat(part2Assignees);
        } else {
            newPart1Assignees = [assigneeId].concat(part1Assignees);
            newPart2Assignees = part2Assignees.filter(
                (assignee) => assignee !== assigneeId
            );
        }

        const updates = {
            partialListsConfig: [
                {
                    categories: partialListsConfig[0].categories,
                    assignees: newPart1Assignees,
                },
                {
                    categories: partialListsConfig[1].categories,
                    assignees: newPart2Assignees,
                },
            ],
        };

        await updatePartialList({
            listId,
            updates,
        });
    };

    const switchAssignees = async () => {
        const updates = {
            partialListsConfig: [
                {
                    categories: partialListsConfig[0].categories,
                    assignees: partialListsConfig[1].assignees,
                },
                {
                    categories: partialListsConfig[1].categories,
                    assignees: partialListsConfig[0].assignees,
                },
            ],
        };

        await updatePartialList({
            listId,
            updates,
        });

        setSwitchAssigneesIcon(
            <CheckIcon className="w-5 h-5 text-green-500" />
        );

        setTimeout(() => {
            setSwitchAssigneesIcon(LeftRightIcon);
        }, 1500);
    };

    const numOfItemsPart1 = Object.values(items).filter((item) =>
        partialListsConfig[0].categories.includes(item.categoryId)
    ).length;

    const numOfItemsPart2 = Object.values(items).filter((item) =>
        partialListsConfig[1].categories.includes(item.categoryId)
    ).length;

    const isAloneInList = participants?.length === 1;

    return (
        <ListCXT listId={listId}>
            <ListNavbar
                button={<span className="text-xl font-medium">- פיצול</span>}
            />
            {items?.length === 0 ? (
                <div className="grid place-content-center text-2xl mt-32">
                    הרשימה ריקה - הוסיפו קודם פריטים לרשימה שלכם
                </div>
            ) : (
                <div className="flex relative flex-col gap-5 mt-28 pb-8">
                    <div className="flex w-full flex-col gap-5 fixed lg:px-60 top-24 h-28 left-0 pb-2 shadow-sm px-5 bg-white z-20">
                        <div className="w-full flex justify-between items-center">
                            <label className="text-lg">מצב מפוצל:</label>
                            <Switch
                                disabled={isAloneInList && !splitMode}
                                checked={splitMode}
                                onChange={toggleSplitMode}
                                className={`${
                                    splitMode ? "bg-green-500" : "bg-slate-300"
                                } relative justify-end inline-flex h-6 items-center w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                            >
                                <span className="sr-only">save items</span>
                                <span
                                    aria-hidden="true"
                                    className={`${
                                        splitMode
                                            ? "translate-x-6"
                                            : "translate-x-0"
                                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out`}
                                />
                            </Switch>
                        </div>
                        {splitMode ? (
                            <div className="flex w-full items-center gap-4 justify-between">
                                <AssigneesDropdown
                                    assignees={part1Assignees}
                                    part={1}
                                    onSelect={onToggleAssignee}
                                    selectDisabled={part1Assignees.length === 1}
                                />
                                <button type="button" onClick={switchAssignees}>
                                    {switchAssigneesIcon}
                                </button>
                                <AssigneesDropdown
                                    assignees={part2Assignees}
                                    part={2}
                                    onSelect={onToggleAssignee}
                                    selectDisabled={part2Assignees.length === 1}
                                />
                            </div>
                        ) : (
                            <div className="w-full text-lg">
                                {isAloneInList
                                    ? "נראה שאתם לבד כאן. צריך לפחות 2 משתתפים כדי לפצל"
                                    : "פיצול וחלוקת הרשימה ל-2 חלקים"}
                            </div>
                        )}
                    </div>
                    <Transition
                        show={splitMode}
                        enter="transition-opacity duration-150"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="mt-32 flex flex-col gap-5">
                            <PartDisclosure
                                categories={categoriesPart1}
                                part={1}
                                toggleParts={toggleParts}
                                numOfItems={numOfItemsPart1}
                            />
                            <PartDisclosure
                                categories={categoriesPart2}
                                part={2}
                                toggleParts={toggleParts}
                                numOfItems={numOfItemsPart2}
                            />
                        </div>
                    </Transition>
                </div>
            )}
        </ListCXT>
    );
}

function AssigneesDropdown({
    assignees,
    onSelect,
    part,
    selectDisabled,
}: {
    assignees: string[];
    onSelect: ({
        assigneeId,
        part,
    }: {
        assigneeId: string;
        part: 1 | 2;
    }) => void;
    part: 1 | 2;
    selectDisabled: boolean;
}) {
    return (
        <Menu as="div" className="relative w-full">
            <Menu.Button
                className={`${
                    part === 1
                        ? "text-slate-800 border-emerald-200/75 border-[1.5px] rounded-md"
                        : "text-slate-800 border-sky-200/75 border-[1.5px] rounded-md"
                } py-2 px-2 flex items-center w-full text-center justify-between h-10 text-base focus:outline-none`}
            >
                משתתפים {part} <ChevronDownIcon className="w-5 h-5 pr-1" />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute top-12 bg-white border shadow-sm rounded-lg right-0 w-full z-20 text-slate-800 flex flex-col gap-0 divide-y
                    `}
                >
                    {assignees.map((assigneeId) => {
                        return (
                            <Menu.Item key={assigneeId}>
                                <button
                                    onClick={() => {
                                        onSelect({ assigneeId, part });
                                    }}
                                    disabled={selectDisabled}
                                    className={`group flex ${
                                        part === 1
                                            ? "flex-row"
                                            : "flex-row-reverse"
                                    } gap-2 text-base items-center justify-center py-3 text-center`}
                                >
                                    {formatPhoneNumber({
                                        phoneNumber: assigneeId,
                                    })}
                                    {LeftRightIcon}
                                </button>
                            </Menu.Item>
                        );
                    })}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

const PartDisclosure = ({
    categories,
    part,
    toggleParts,
    numOfItems,
}: {
    categories: Array<{ id: string; inPart: 1 | 2; numOfItems: number }>;
    part: 1 | 2;
    numOfItems: number;
    toggleParts: ({
        categoryId,
        inPart1,
    }: {
        categoryId: string;
        inPart1: boolean;
    }) => void;
}) => {
    const [parent] = useAutoAnimate();

    const isShowingPart1 = part === 1;

    return (
        <div className="flex flex-col gap-3 text-[1.35rem]">
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        <Disclosure.Button
                            className={`flex justify-between items-center text-start px-2 py-2 
                        ${
                            isShowingPart1
                                ? "bg-emerald-200/75"
                                : "bg-sky-200/75"
                        }
                        rounded`}
                        >
                            <div>
                                חלק {part}{" "}
                                <span className="mr-2 text-slate-600 text-sm">
                                    <span className="font-poppins">
                                        {numOfItems}{" "}
                                    </span>
                                    פריטים
                                </span>
                            </div>
                            <ChevronDownIcon
                                className={`${
                                    open
                                        ? "transform rotate-180"
                                        : "transform rotate-0"
                                } transition-all duration-300 w-6 h-6`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel
                            className="flex flex-col divide-y divide-slate-200 w-full pb-5"
                            ref={parent}
                        >
                            {categories.map((cat) => {
                                const categoryData = getCategoryById({
                                    id: cat.id,
                                });
                                const inPart1 = cat.inPart === 1;
                                return (
                                    <li
                                        key={cat.id}
                                        className="flex justify-between items-center py-3"
                                    >
                                        <div className="flex items-center gap-2">
                                            <div>{categoryData.icon}</div>
                                            <div>{categoryData.title}</div>
                                            <div className="font-poppins text-sm pr-2 text-slate-600">
                                                {cat.numOfItems}
                                            </div>
                                        </div>
                                        <div className="flex gap-2 items-center font-poppins">
                                            <button
                                                disabled={inPart1}
                                                className={`${
                                                    inPart1
                                                        ? `${part1bg} text-slate-800`
                                                        : "text-slate-800 border border-slate-300"
                                                    // : inPart1
                                                    // ? "text-slate-800 border border-slate-300"
                                                    // : `${part2bg} text-slate-800`
                                                } p-2 flex items-center justify-center w-10 h-10 text-xl rounded-full`}
                                                onClick={() => {
                                                    toggleParts({
                                                        categoryId: cat.id,
                                                        inPart1,
                                                    });
                                                }}
                                            >
                                                1
                                            </button>
                                            <button
                                                disabled={!inPart1}
                                                className={`${
                                                    isShowingPart1
                                                        ? inPart1
                                                            ? "text-slate-800 border border-slate-300"
                                                            : `${part2bg} text-slate-800`
                                                        : inPart1
                                                        ? "text-slate-800 border border-slate-300"
                                                        : `${part2bg} text-slate-90`
                                                } p-1 flex items-center justify-center w-10 h-10 text-xl rounded-full`}
                                                onClick={() => {
                                                    toggleParts({
                                                        categoryId: cat.id,
                                                        inPart1,
                                                    });
                                                }}
                                            >
                                                2
                                            </button>
                                        </div>
                                    </li>
                                );
                            })}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};
