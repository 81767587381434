import { Dialog, Transition } from "@headlessui/react";
import useAlert from "hooks/useAlert";
import { Fragment, useEffect } from "react";

const AlertModal = () => {
  const { prompt = "", isOpen = false, proceed, cancel } = useAlert();
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (proceed && isOpen && e.key === "Enter") {
        proceed();
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [proceed, isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        dir="rtl"
        as="div"
        className="font-assistant relative z-50"
        onClose={cancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-start align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-medium leading-6 text-gray-900"
                >
                  שימו לב
                </Dialog.Title>
                <div className="mt-2">
                  <div
                    className="text-lg text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: prompt,
                    }}
                  />
                </div>

                <div className="text-lg mt-4 flex gap-3 justify-end">
                  <button
                    type="button"
                    className="btn-primary w-24"
                    onClick={proceed}
                  >
                    הבנתי
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AlertModal;
