// import { DocumentData, doc } from "firebase/firestore";
// import { useDocumentData } from "react-firebase-hooks/firestore";
// import { UserData } from "types";
// import { USERS_COLLECTION, db } from "../firebase";

// export type UserDataExtended = UserData & { userId: string };

// export default function useUserData({ userId }: { userId: string }) {
//     const [value, loading, error] = useDocumentData<DocumentData>(
//         doc(db, USERS_COLLECTION, userId)
//     );

//     console.log("useUserData", value, loading, error);
//     if (!value) return { loading, error };

//     return {
//         userData: value
//             ? ({
//                   ...value,
//                   userId,
//               } as UserDataExtended)
//             : undefined,
//         loading,
//         error,
//     };
// }

import { signInWithCustomToken, User } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { UserData } from "types";
import { USERS_COLLECTION, auth, authenticateUser, db } from "../firebase";
import useGrocerooToken from "./useGrocerooToken";
import { updateUser } from "db-utils";

export const useUserData = ({ userId }: { userId: string }) => {
    // const [userData, loading, error] = useDocumentData<DocumentData>(
    //     doc(db, USERS_COLLECTION, userId)
    // );
    const [currentUser, setCurrentUser] = useState<undefined | User | null>();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
        });
        return () => unsubscribe();
    }, []);

    const [userData, setUserData] = useState<UserData | undefined | null>(
        undefined
    );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>();

    const grocerooToken = useGrocerooToken();

    useEffect(() => {
        let unsubscribe = () => {};
        const getUserData = async (user: User) => {
            unsubscribe = onSnapshot(
                doc(db, USERS_COLLECTION, user.uid),
                (doc) => {
                    if (doc.data() === undefined) {
                        setError("User data is undefined");
                        setLoading(false);
                        setUserData(null);
                        return;
                    }
                    const { onboardingProgress } = doc.data() as UserData;
                    setUserData(doc.data() as UserData);
                    setError(undefined);
                    setLoading(false);
                    if (
                        onboardingProgress &&
                        !onboardingProgress.viewedWebapp
                    ) {
                        updateUser({
                            userId: user.uid,
                            updates: {
                                onboardingProgress: {
                                    ...onboardingProgress,
                                    viewedWebapp: true,
                                },
                            },
                        });
                    }
                },
                (error) => {
                    console.error(error);
                    setError("Error getting user data");
                    setLoading(false);
                    setUserData(null);
                }
            );
        };
        const authenticate = async () => {
            try {
                console.log("User not logged in");
                if (grocerooToken !== undefined) {
                    const res = (await authenticateUser({
                        uid: userId,
                        grocerooToken,
                    })) as {
                        data: { token: string | null; success: boolean };
                    };
                    const { token, success } = res.data;

                    if (!token || success === false) {
                        setError("auth error");
                        setLoading(false);
                        setUserData(null);
                        return;
                    }

                    signInWithCustomToken(auth, token)
                        .then((userCredential) => {
                            const user = userCredential.user;
                            getUserData(user);
                        })
                        .catch((e) => {
                            console.error(e);
                            setError("Auth catch error");
                            setUserData(null);
                            setLoading(false);
                        });
                }
            } catch (e) {
                console.error(e);
                setError("Auth catch error");
                setLoading(false);
                setUserData(null);
            }
        };

        if (typeof userId === "string" && userId.length === 0) {
            setLoading(false);
            setUserData(null);
            return;
        }
        // hasn't finished "auth.onAuthStateChanged" yet
        if (!userId || currentUser === undefined) return;

        const hasUserChanged = currentUser?.uid !== userId;
        // "auth.onAuthStateChanged" and not logged in OR user has changed
        if (currentUser === null || hasUserChanged) {
            authenticate();
        } else {
            getUserData(currentUser);
        }
        return () => unsubscribe();
    }, [grocerooToken, userId, currentUser]);
    return { userData: userData as UserData, loading };
};

export default useUserData;
