import {
  AdjustmentsVerticalIcon,
  BanknotesIcon,
  BookmarkIcon,
  ClockIcon,
  PencilIcon,
  ShoppingCartIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Link from "components/Link";
import { ListSettingsMenu } from "components/list/ListMenu";
import useIsIphoneAndPWA from "hooks/useIsIphoneAndPWA";
import useListData, { ListDataExtended } from "hooks/useListData";
import { createContext, useState } from "react";
import { Global, css } from "@emotion/react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import CookIcon from "components/icons/CookIcon";

type ListContextType = {
  listData: ListDataExtended | undefined;
  loading: boolean;
  error: unknown;
};

export const ListContext = createContext<ListContextType>({
  listData: undefined,
  loading: false,
  error: null,
});

export default function ListCXT({
  listId,
  children,
}: {
  listId: string;
  children: React.ReactNode;
}) {
  const { listData: value, loading, error } = useListData({ listId });
  const [openMenu, setOpenMenu] = useState(false);

  // const [listData, setListData] = useState<ListDataExtended | undefined>(
  //     undefined
  // );

  // useEffect(() => {
  //     if (!value) return;
  //     // return;
  //     console.log("value", value);
  //     // setListData({ ...value, id: listId } as ListDataExtended);
  //     const { savedItems, items } = value as ListDataExtended;
  //     console.log("savedItems", savedItems, value);
  //     const updatedItems: Array<Item> = items;

  //     for (const savedItem of savedItems) {
  //         const existingItemIndex = items.findIndex(
  //             (i) => i.text === savedItem.text
  //         );
  //         if (existingItemIndex !== -1) {
  //             const existingItemInList = items[existingItemIndex];
  //             updatedItems[existingItemIndex] = {
  //                 ...existingItemInList,
  //                 saved: true,
  //             };
  //         }
  //     }

  //     const allItemsAreUpToDate = savedItems.every((savedItem) => {
  //         const existingItem = items.find((i) => i.text === savedItem.text);
  //         if (!existingItem) return true;
  //         return existingItem.saved;
  //     });

  //     if (listData && allItemsAreUpToDate) {
  //         console.log("allItemsAreUpToDate");
  //         return;
  //     }

  //     setListData({ ...value, items: updatedItems } as ListDataExtended);
  // }, [value, listData, listId]);

  return (
    <ListContext.Provider value={{ listData: value, loading, error }}>
      {children}
      <>
        <ListBottomNavbar {...{ listId, setOpenMenu }} />
        <ListSettingsMenu {...{ openMenu, setOpenMenu }} />
      </>
    </ListContext.Provider>
  );
}

export const ListBottomNavbar = ({
  listId,
  setOpenMenu,
}: {
  listId: string | null;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const DRAWER_CLOSED_HEIGHT = 74;
  const DRAWER_OPEN_HEIGHT = 158;
  const OPEN_THRESHOLD = 90; // px
  const CLOSE_THRESHOLD = 136; // px
  const isIphoneAndPWA = useIsIphoneAndPWA();
  const [drawerHeight, setDrawerHeight] = useState(DRAWER_CLOSED_HEIGHT);
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const touchStart = React.useRef(0);
  const currentHeight = React.useRef(DRAWER_CLOSED_HEIGHT);

  const isOpen = React.useMemo(
    () => drawerHeight > DRAWER_CLOSED_HEIGHT,
    [drawerHeight]
  );

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStart.current = e.touches[0].clientY;
    currentHeight.current = drawerHeight;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchStart.current) return;

    const deltaY = touchStart.current - e.touches[0].clientY;
    const newHeight = Math.max(
      DRAWER_CLOSED_HEIGHT,
      Math.min(DRAWER_OPEN_HEIGHT, currentHeight.current + deltaY)
    );
    setDrawerHeight(newHeight);
  };

  const handleTouchEnd = () => {
    touchStart.current = 0;
    setDrawerHeight(
      isOpen
        ? drawerHeight > CLOSE_THRESHOLD
          ? DRAWER_OPEN_HEIGHT
          : DRAWER_CLOSED_HEIGHT
        : drawerHeight > OPEN_THRESHOLD
        ? DRAWER_OPEN_HEIGHT
        : DRAWER_CLOSED_HEIGHT
    );
  };

  return (
    <>
      <Global
        styles={css`
          #bottom-navbar.MuiDrawer-root > .MuiPaper-root {
            height: ${drawerHeight}px !important;
            min-height: DRAWER_CLOSED_HEIGHTpx !important;
            overflow: visible;
            z-index: 20;
            bottom: 0 !important;
            visibility: visible !important;
          }
          #bottom-navbar .MuiBackdrop-root {
            display: none;
          }
        `}
      />
      <SwipeableDrawer
        disableBackdropTransition={true}
        disableDiscovery={iOS}
        anchor="bottom"
        id="bottom-navbar"
        open={true}
        onClose={() => {
          setDrawerHeight(DRAWER_CLOSED_HEIGHT);
        }}
        onOpen={() => {
          setDrawerHeight(DRAWER_OPEN_HEIGHT);
        }}
        hideBackdrop
        disableSwipeToOpen={false}
        swipeAreaWidth={0}
        variant="permanent"
        PaperProps={{
          sx: {
            position: "fixed",
            transition: "height 0.3s ease",
            touchAction: "none",
            minHeight: `${DRAWER_CLOSED_HEIGHT}px`,
          },
          onTouchStart: handleTouchStart,
          onTouchMove: handleTouchMove,
          onTouchEnd: handleTouchEnd,
        }}
      >
        <div dir="rtl" className="font-assistant bg-white h-full">
          <div className="w-full relative">
            <div
              style={{
                height: `${drawerHeight}px`,
                transition: "height 0.3s ease",
              }}
              className={`z-20 transition-all bg-white ${
                isOpen ? "py-3" : isIphoneAndPWA ? "pt-3 pb-10" : "py-3"
              } shadow-[0_35px_60px_-5px_rgba(0,0,0,0.4)] fixed bottom-0 left-0 w-full grid grid-cols-4 gap-y-4 justify-between px-2 xl:px-40 2xl:px-60 divide-x divide-slate-100`}
            >
              <button
                onClick={() =>
                  setDrawerHeight(
                    isOpen ? DRAWER_CLOSED_HEIGHT : DRAWER_OPEN_HEIGHT
                  )
                }
                className="ring-6 ring-transparent h-[1.5rem] w-[1.6rem] rounded-full bg-white/95 flex items-center justify-center absolute top-0 right-1/2 translate-x-1/2 -translate-y-[0.45rem]"
              >
                <ChevronUpIcon
                  className={`w-5 h-5 -mt-[0.3rem] text-slate-200/70 ${
                    isOpen ? "rotate-180 mt-0" : ""
                  } transition-all`}
                />
              </button>
              {[
                {
                  title: "רשימה",
                  icon: (
                    <ShoppingCartIcon className="w-5 h-5 group-[.active]:fill-green-500" />
                  ),
                  href: `/list/${listId}`,
                },
                {
                  title: "עריכה",
                  icon: (
                    <PencilIcon className="w-5 h-5 group-[.active]:fill-orange-300" />
                  ),
                  href: `/list/${listId}/edit`,
                },
                {
                  title: "היסטוריה",
                  icon: (
                    <ClockIcon className="w-5 h-5 group-[.active]:fill-[#eae0d5]" />
                  ),
                  href: `/list/${listId}/history`,
                },
                {
                  title: "פעולות",
                  icon: (
                    <AdjustmentsVerticalIcon className="w-5 h-5 group-[.active]:fill-blue-500" />
                  ),
                  onClick: () => setOpenMenu(true),
                },
                {
                  title: "שמורים",
                  icon: (
                    <BookmarkIcon className="w-5 h-5 group-[.active]:fill-yellow-300" />
                  ),
                  href: `/list/${listId}/saved-items`,
                },
                {
                  title: "קופונים",
                  icon: (
                    <BanknotesIcon className="w-5 h-5 group-[.active]:fill-green-500" />
                  ),
                  href: `/list/${listId}/offers`,
                  comingSoon: true,
                  disabled: true,
                },
                {
                  title: "מתכונים",
                  icon: (
                    <CookIcon className="w-5 h-5 group-[.active]:fill-orange-300" />
                  ),
                  href: `/list/${listId}/recipes`,
                  comingSoon: true,
                  disabled: true,
                },

                {
                  title: "איזור אישי",
                  icon: (
                    <UserIcon className="w-5 h-5 group-[.active]:fill-yellow-300" />
                  ),
                  href: `/account`,
                },
              ]
                .slice(0, isOpen ? 8 : 4)
                .map(({ title, icon, href, onClick, disabled, comingSoon }) => {
                  const isActive =
                    window.location.pathname === href ||
                    (title === "עריכה" &&
                      window.location.pathname.includes("edit")) ||
                    (title === "היסטוריה" &&
                      window.location.pathname.includes("history")) ||
                    (title === "שמורים" &&
                      window.location.pathname.includes("saved-items")) ||
                    (title === "פעולות" &&
                      (window.location.pathname.includes("participants") ||
                        window.location.pathname.includes("split") ||
                        window.location.pathname.includes("category-order")));
                  return (
                    <Link
                      key={title}
                      to={href}
                      onClick={() => {
                        onClick && onClick();
                        window.scrollTo(0, 0);
                      }}
                      disabled={disabled || listId === null}
                      className={`${
                        disabled
                          ? "text-slate-400/80"
                          : isActive
                          ? "bg-green-50 rounded-full font-semibold active"
                          : ""
                      } relative flex-1 flex flex-col gap-0.5 text-center items-center justify-center`}
                    >
                      {comingSoon && <ComingSoonBadge />}
                      <span className={`${isActive ? "group active" : ""}`}>
                        {icon}
                      </span>
                      <span className="text-sm">{title}</span>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export const ComingSoonBadge = () => (
  <div className="absolute -top-2 right-2 grid place-content-center">
    <ComingSoon />
  </div>
);

export const ComingSoon = () => (
  <div className="bg-red-500 text-white text-xs font-medium rounded-full text-center w-[2.75rem] h-4 shrink-0">
    בקרוב
  </div>
);
