import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Fragment, useEffect, useMemo, useState } from "react";

import { sortItemsByCategory } from "categories";
import LoadingComponents from "components/Loader";
import { ListNavbar } from "components/Navbar";
import AddItemPopup from "components/list/AddItemPopup";
import EditItemDrawer from "components/list/EditItemDrawer";
import EditListItem from "components/list/EditListItem";
import ListCXT from "context/ListContext";
// import { useFirebaseRoutesAnalytics } from "hooks/useFirebaseAnalytics";
import { Menu, Transition } from "@headlessui/react";
import {
    CalculatorIcon,
    ChevronDownIcon,
    PencilIcon,
} from "@heroicons/react/24/outline";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useUser from "hooks/useUser";
import { Item } from "types";
import useIsIphoneAndPWA from "hooks/useIsIphoneAndPWA";

export default function EditListPage() {
    const { listId } = useListIdFromParams();
    const { user, loading: userLoading } = useUser();

    // useFirebaseRoutesAnalytics({ listId });
    const { listData, loading, error } = useListData({ listId });

    const [listToShow, setListToShow] = useState<Array<Item>>();
    const { items: listInDB, categoriesSortOrder } = listData ?? {};
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [itemIdToEdit, setItemIdToEdit] = useState<string | undefined>();
    const [isQuantitiesMode, setIsQuantitiesMode] = useState(false);

    const [parent] = useAutoAnimate({
        duration:
            listToShow?.length ?? 0 <= 15
                ? 400
                : listToShow?.length ?? 0 <= 30
                ? 600
                : 1300,
    });

    const userId = user?.userId;

    useEffect(() => {
        if (!listInDB) return;

        const sortedList = sortItemsByCategory({
            items: listInDB,
            categoriesSortOrder,
        });

        const listWithMarkedAtBottom = [...sortedList].sort(
            (firstItem: Item, secondItem: Item) => {
                const { checked: a } = firstItem;
                const { checked: b } = secondItem;
                if (a && !b) return 1;
                if (!a && b) return -1;
                return 0;
            }
        );

        setListToShow(listWithMarkedAtBottom);
    }, [listInDB, categoriesSortOrder, userId]);

    const listProgress = useMemo(
        () =>
            listInDB
                ? listInDB.filter((item) => item.checked || item.missing)
                      .length / listInDB.length
                : 0,
        [listInDB]
    );

    const isIphoneAndPWA = useIsIphoneAndPWA();

    if (loading || userLoading || !listToShow)
        return <LoadingComponents fullPage isList />;

    if (error || !listData || !listInDB)
        return (
            <div className="flex items-center justify-center flex-col gap-5 mt-16">
                <h1 className="text-3xl font-medium">לא נמצאה רשימה</h1>
                <p className="text-lg">יש להיכנס דרך הקישור שקיבלתם בוואטסאפ</p>
            </div>
        );

    if (!userId) {
        return (
            <div className="flex pt-20 mt-16 flex-col gap-5 items-center justify-center h-full text-center text-3xl">
                <h1>לא נמצא משתמש</h1>
                <div className="text-xl">
                    יש להיכנס דרך הקישור שקיבלתם בוואטסאפ
                </div>
            </div>
        );
    }

    return (
        <ListCXT listId={listId}>
            <EditDrawer
                {...{ openEditDrawer, setOpenEditDrawer }}
                itemId={itemIdToEdit}
            />
            <div className="h-full relative mt-16">
                <div className="flex w-full gap-2 items-center">
                    <ListNavbar
                        isEditMode={true}
                        listProgress={listProgress}
                        showSplitMode={false}
                        button={
                            <Menu className="relative" as={"div"}>
                                <Menu.Button className="flex items-center gap-2 text-xl font-medium">
                                    {isQuantitiesMode ? "- כמויות" : " - עריכה"}
                                    <ChevronDownIcon className="w-5 h-5 text-slate-700" />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="text-lg absolute right-0 mt-2 w-40 flex flex-col origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none p-1.5">
                                        <Menu.Item>
                                            <button
                                                className={`${
                                                    isQuantitiesMode
                                                        ? ""
                                                        : "bg-blue-100"
                                                } w-full rounded-md text-start flex justify-between items-center p-2`}
                                                onClick={() =>
                                                    setIsQuantitiesMode(false)
                                                }
                                            >
                                                עריכה
                                                <PencilIcon className="w-6 h-6" />
                                            </button>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <button
                                                className={`${
                                                    isQuantitiesMode
                                                        ? "bg-blue-100"
                                                        : ""
                                                } w-full rounded-md text-start flex justify-between items-center p-2`}
                                                onClick={() =>
                                                    setIsQuantitiesMode(true)
                                                }
                                            >
                                                כמויות
                                                <CalculatorIcon className="w-6 h-6" />
                                            </button>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        }
                    />
                </div>
                <div
                    className={`fixed ${
                        isIphoneAndPWA ? "bottom-28" : "bottom-20"
                    } right-0 pr-4 pl-2 z-20 w-full`}
                >
                    <AddItemPopup />
                </div>
                <ul
                    ref={parent}
                    className="flex flex-col divide-y divide-slate-50 text-2xl pt-6 w-full pb-20"
                >
                    {listToShow.length > 0 ? (
                        listToShow.map((listItem) => (
                            <EditListItem
                                key={listItem.text}
                                {...listItem}
                                onItemSelect={() => {
                                    setItemIdToEdit(listItem.text);
                                    setOpenEditDrawer(true);
                                }}
                                onItemLongPress={() => {
                                    setIsQuantitiesMode((prev) => !prev);
                                }}
                                isQuantitiesMode={isQuantitiesMode}
                            />
                        ))
                    ) : (
                        <div className="grid place-content-center text-2xl">
                            אין פריטים להציג
                        </div>
                    )}
                </ul>
            </div>
        </ListCXT>
    );
}

export const EditDrawer = ({
    itemId,
    openEditDrawer,
    setOpenEditDrawer,
    isSavedItemsMode = false,
}: {
    itemId?: string;
    openEditDrawer: boolean;
    setOpenEditDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    isSavedItemsMode?: boolean;
}) => {
    const { listId } = useListIdFromParams();
    const { listData } = useListData({ listId });

    const item = listData?.[isSavedItemsMode ? "savedItems" : "items"].find(
        (item) => itemId && item.text === itemId
    );
    if (!item) return null;

    return (
        <EditItemDrawer
            open={openEditDrawer}
            setOpen={setOpenEditDrawer}
            onClose={() => setOpenEditDrawer(false)}
            onOpen={() => setOpenEditDrawer(true)}
            isSavedItemsMode={isSavedItemsMode}
            {...item}
        />
    );
};
