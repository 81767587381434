import React from "react";

interface ItemsCountProps {
  itemsCount: number;
  setItemsCount: (value: number) => void;
}

export default function ItemsCount({
  itemsCount,
  setItemsCount,
}: ItemsCountProps) {
  const handleItemsButton = (value: number) => setItemsCount(value);

  return (
    <div className="flex flex-col gap-5">
      {/* <input
        type="number"
        className="font-medium border-b border-slate-300 text-lg px-2 py-0 w-12 mx-1 h-8 text-center font-poppins bg-transparent focus:outline-none focus:border-black"
        value={itemsCount}
        onChange={(e) => setItemsCount(Number(e.target.value))}
      /> */}
      <div className="flex gap-0.5 items-center w-full text-slate-500 text-base">
        <label className="">מוכרים</label>
        <input
          type="number"
          className="font-medium border-b border-slate-300 text-base text-slate-900 px-2 py-0 w-8 h-6 mx-1 text-center font-poppins bg-transparent focus:outline-none focus:border-black"
          // className="font-medium rounded-full bg-white text-base px-2 py-0 h-10 w-10 text-center font-poppins bg-transparent focus:outline-none focus:border-black"
          value={itemsCount}
          onChange={(e) => setItemsCount(Number(e.target.value))}
          min="1"
        />
        <span>יח'</span>
      </div>
      <div className="flex gap-2 justify-between items-center w-full">
        {[2, 3, 4, 5, 6].map((num) => (
          <button
            key={num}
            onClick={() => handleItemsButton(num)}
            className={`text-lg font-poppins font-medium flex items-center justify-center shadow-sm h-10 w-10 rounded-lg ${
              num === itemsCount
                ? "bg-slate-900 text-white"
                : "bg-white text-slate-600 border border-slate-100"
            }`}
          >
            {num}
          </button>
        ))}
      </div>
    </div>
  );
}
