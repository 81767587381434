import Link from "./Link";

export default function Footer({ lean = false }: { lean?: boolean }) {
    return (
        <footer className="font-assistant bg-white relative z-10 flex flex-col gap-3 h-24 py-3 items-center justify-center">
            <div
                dir="rtl"
                className={`grid grid-cols-4 justify-start lg:flex ${
                    lean ? "gap-2.5 z-50" : "gap-2.5 lg:gap-10"
                } flex-row items-center justify-between text-sm text-center md:text-start`}
            >
                {!lean && (
                    <a
                        className="underline"
                        href="https://wa.me/972587501168"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        דברו איתנו
                    </a>
                )}
                <Link outsideUserContext to="/terms-of-service" className="">
                    תנאי שימוש
                </Link>
                <Link external outsideUserContext to="/privacy" className="">
                    מדיניות פרטיות
                </Link>
                <Link outsideUserContext to="/cancel" className="text-sm">
                    ביטול עסקה
                </Link>
                <Link outsideUserContext to="/accessibility" className="">
                    הצהרת נגישות
                </Link>
            </div>
            {!lean && (
                <Link
                    to="mailto:contact@heysally.co.il"
                    external
                    className="flex gap-1 font-poppins items-center justify-center text-xs lg:text-sm mx-2.5"
                >
                    contact@heysally.co.il
                </Link>
            )}
            <div className="flex gap-1 font-poppins items-center justify-center pb-2 text-xs lg:text-sm">
                <span className="font-assistant">סאלי</span>
                Sally {new Date().getFullYear()} &copy; All Rights Reserved
            </div>
        </footer>
    );
}
