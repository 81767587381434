import React from "react";

interface WhatsappIconProps extends React.SVGProps<SVGSVGElement> {
    className?: string;
}

export const WhatsappIcon: React.FC<WhatsappIconProps> = ({
    className = "",
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 64 64"
            className={className}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m45.132 40.499-6.47-1.217c-1.102-.206-1.836-1.326-1.642-2.499.194-1.174 1.243-1.956 2.345-1.75l7.074 1.33a4.438 4.438 0 0 1 3.488 5.145c-.01.054-.027.102-.037.155-.327 1.173-.875 2.247-1.678 3.171-4.615 5.305-15.559 3.777-24.443-3.416-8.885-7.19-12.347-17.321-7.73-22.625 1.098-1.264 2.56-2.131 4.264-2.631.143-.036.281-.087.424-.108 0 0 .002-.003.005-.003a4.3 4.3 0 0 1 4.614 2.616l2.519 6.749c.373.998-.155 2.102-1.181 2.465-1.023.364-2.155-.15-2.528-1.148l-2.315-6.204a.89.89 0 0 0-1.091-.475 4.952 4.952 0 0 0-1.646 1.219c-3.195 3.673.043 11.37 7.236 17.19s15.615 7.563 18.809 3.891c.197-.228.361-.473.508-.729a.757.757 0 0 0 .084-.232.793.793 0 0 0-.609-.894zM6 42a2 2 0 1 1-3.999.001A2 2 0 0 1 6 42zm26 22a31.812 31.812 0 0 1-14.002-3.239c-.065-.028-.128-.058-.189-.087s-.124-.056-.185-.087a5.488 5.488 0 0 0-3.915-.393l-.08.019-5.804 1.612c-.021.005-.046.005-.068.013a4.53 4.53 0 0 1-2.415 0 4.545 4.545 0 0 1-3.18-5.588c.014-.056.019-.098.031-.148L3.889 50H4a2 2 0 1 1 4 0h.041l-.065.235c-.024.216-.08.419-.169.608L6.012 57.31c0 .004.007-.003.007.002a.544.544 0 0 0 .639.677c.005 0-.002.01 0 .01l5.952-1.654.032-.004a9.456 9.456 0 0 1 6.737.651c.02.01.043.013.065.02A27.835 27.835 0 0 0 32 60c15.465 0 28-12.536 28-28S47.465 4 32 4C16.536 4 4 16.536 4 32c0 .587.029 1.169.065 1.745l-.09.008c.01.08.025.162.025.247a2 2 0 1 1-3.947-.441A29.912 29.912 0 0 1 0 32C0 14.327 14.327 0 32 0s32 14.327 32 32-14.327 32-32 32z"
            />
        </svg>
    );
};
