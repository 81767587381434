import React from "react";

const MAX_PRICE = 70;
const MIN_PRICE = 10;
interface OfferPriceProps {
  offerPrice: number;
  setOfferPrice: React.Dispatch<React.SetStateAction<number>>;
}

export default function OfferPrice({
  offerPrice,
  setOfferPrice,
}: OfferPriceProps) {
  return (
    <div className="flex flex-col gap-5 mt-2">
      <div className="flex gap-2 justify-between items-center w-full">
        <div className="text-base text-slate-500">
          <label className="">במחיר</label>
          <input
            type="number"
            className="font-medium border-b border-slate-300 text-base text-slate-900 px-2 py-0 w-[3.25rem] h-6 mx-1 text-center font-poppins bg-transparent focus:outline-none focus:border-black"
            value={offerPrice % 1 !== 0 ? offerPrice.toFixed(1) : offerPrice}
            onChange={(e) => setOfferPrice(Number(e.target.value))}
          />
          <span className="">₪</span>
        </div>
        <button
          onClick={() => setOfferPrice((prev) => prev + 0.9)}
          className="font-poppins text-sm text-slate-500"
        >
          0.90₪+
        </button>
      </div>
      <div className="flex items-center gap-2 w-full font-poppins text-sm">
        <button onClick={() => setOfferPrice(MAX_PRICE)}>₪{MAX_PRICE}</button>
        <input
          type="range"
          className="w-full h-1.5 bg-slate-200/80 rounded-lg appearance-none cursor-pointer focus:outline-none touch-none accent-black"
          min={MIN_PRICE}
          max={MAX_PRICE}
          style={{ direction: "ltr" }}
          value={offerPrice}
          onChange={(e) => setOfferPrice(Number(e.target.value))}
          onPointerDown={(e) => e.stopPropagation()}
          onPointerMove={(e) => e.stopPropagation()}
          onPointerUp={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
        />
        <button onClick={() => setOfferPrice(MIN_PRICE)}>₪{MIN_PRICE}</button>
      </div>
    </div>
  );
}
