import {
  Bars3Icon,
  ChevronDownIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { BoltIcon } from "@heroicons/react/24/solid";
import splitIcon from "assets/images/split icon.png";
import UserCXT from "context/UserContext";
import useUser from "hooks/useUser";
import { useLocation, useNavigate } from "react-router-dom";
import { isPremium } from "utils";
import Drawer from "./Drawer";
import Link from "./Link";
import Logo from "./Logo";
import ListProgressBar from "./list/ListProgressBar";
import { updateUser } from "db-utils";
import useListIdFromParams from "hooks/useListIdFromParams";
import { botConversationStarterLink } from "../constants";
import useListsData from "hooks/useListsData";
import { Fragment, useEffect, useState } from "react";
import { Disclosure, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Footer from "./Footer";
import FinishedPopup from "./FinishedPopup";
import OffersCalculator from "./OffersCalculator";

const LOCAL_STORAGE_KEY_KEEP_CALC_OPEN = "sally-keep-navbar-calc-open";

export default function Navbar({
  showOnList = false,
}: {
  showOnList?: boolean;
}) {
  const location = useLocation();
  const isListPage = location.pathname.includes("/list/");
  const isListsPage = location.pathname.includes("/lists");
  const isAccountPage = location.pathname.includes("/account");

  const showSideMenu = isListsPage || isAccountPage;

  if (isListPage && !showOnList) return null;

  return (
    <UserCXT>
      <nav
        dir="rtl"
        className="font-assistant relative z-30 px-3 lg:px-10 py-1 flex flex-row-reverse justify-between items-center top-0 left-0 h-14 w-screen bg-gradient-to-b from-green-100 via-green-50/25 to-white"
      >
        <Link outsideUserContext to="/">
          <Logo />
        </Link>
        {showSideMenu ? (
          <SideMenuDrawer buttonStyle="text-slate-700 w-7 h-7" />
        ) : null}
      </nav>
    </UserCXT>
  );
}

export const ListNavbar = ({
  listProgress,
  isEditMode,
  isSavedItems,
  isListPage = false,
  showSplitMode = false,
  hasFinished,
  button,
}: {
  listProgress?: number;
  isEditMode?: boolean;
  isSavedItems?: boolean;
  isListPage?: boolean;
  showSplitMode?: boolean;
  button?: JSX.Element;
  hasFinished?: boolean;
}) => {
  const { user } = useUser();
  const { listId } = useListIdFromParams();

  const [hasFinishedBuying, setHasFinishedBuying] = useState<boolean>(false);
  const [showFinishedPopup, setShowFinishedPopup] = useState<boolean>(false);
  const [isListTheCurrentList, setIsListTheCurrentList] = useState<boolean>(
    user?.userData?.currentListId === listId
  );

  useEffect(() => {
    setIsListTheCurrentList(user?.userData?.currentListId === listId);
  }, [listId, user?.userData?.currentListId]);

  const onFinishedConfirm = () => {
    setHasFinishedBuying(true);
    setShowFinishedPopup(false);
  };

  return (
    <nav
      dir="rtl"
      className={`font-assistant fixed z-20 pb-3 pt-6 px-3 flex flex-col gap-3.5 top-0 left-0 ${
        isSavedItems ? "h-20" : "justify-center h-20"
      } w-screen bg-white lg:px-60`}
    >
      <FinishedPopup
        isOpen={showFinishedPopup}
        onCancel={() => setShowFinishedPopup(false)}
        onConfirm={onFinishedConfirm}
      />
      <div className="flex justify-between items-center w-full">
        <div className="flex gap-3 items-center">
          <SideMenuDrawer />
          <div className="flex gap-1 items-center">
            <ListsDropdown isEditMode={isEditMode} />
            {button ?? null}
          </div>
          {showSplitMode ? (
            <Link to={`split`}>
              <img src={splitIcon} alt="split" className="w-5 h-5 mr-2" />
            </Link>
          ) : null}
        </div>
        <div className="flex gap-2 items-center">
          {hasFinishedBuying && !showSplitMode ? (
            <Link
              className="btn-primary"
              external
              to={`${botConversationStarterLink}${encodeURI("סיכום קניה")}`}
            >
              סיכום קניה
            </Link>
          ) : listProgress && listProgress >= 0.5 ? (
            <button
              className={`${
                hasFinished && !showSplitMode
                  ? "btn-primary"
                  : listProgress >= 0.75
                  ? "btn-outline"
                  : "btn-outline !border-0 !shadow-none font-medium"
              }`}
              onClick={() => setShowFinishedPopup(true)}
            >
              סיימתי
            </button>
          ) : isListPage && !isListTheCurrentList && user?.userId ? (
            <button
              className="text-green-500 textl-sm"
              onClick={() =>
                updateUser({
                  userId: user.userId,
                  updates: {
                    currentListId: listId,
                  },
                })
              }
            >
              החלף לרשימה זו בבוט
            </button>
          ) : null}
          {/* {isUserPremium ? null : (
                        <Link
                            outsideUserContext
                            to={`/premium-info/?phone=${user?.userId}`}
                            className="text-2xl relative"
                        >
                            <BoltIcon className="h-9 p-1 w-9 text-amber-300" />
                            <span className="text-xs text-center w-9 font-semibold absolute -bottom-2.5 left-0">
                                שדרג
                            </span>
                        </Link>
                    )} */}
          {/* <ListSettingsMenu /> */}
          {/* <ListActionsMenu
                        isEditing={isEditing}
                        show={showActionsMenu}
                    /> */}
        </div>
      </div>
      {listProgress !== undefined && !isNaN(listProgress) ? (
        <ListProgressBar progress={listProgress} />
      ) : null}
    </nav>
  );
};

export const SideMenuDrawer = ({
  buttonStyle = "h-7 w-7 text-slate-800",
}: {
  buttonStyle?: string;
}) => {
  const { user } = useUser();

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  // const [keepCalcOpen, setKeepCalcOpen] = useState<boolean>(
  //   localStorage.getItem(LOCAL_STORAGE_KEY_KEEP_CALC_OPEN) === "true"
  // );

  // useEffect(() => {
  //   localStorage.setItem(
  //     LOCAL_STORAGE_KEY_KEEP_CALC_OPEN,
  //     keepCalcOpen.toString()
  //   );
  // }, [keepCalcOpen]);

  if (!user) return null;

  const routes = [
    {
      title: "הרשימות שלי",
      href: `/lists/`,
    },
    // {
    //     title: (
    //         <span className="font-assistant">
    //             סאלי פלוס
    //             <BoltIcon className="mr-1 h-5 w-5 inline-block text-amber-300" />
    //         </span>
    //     ),
    //     href: `/premium-info/?phone=${user.userId}`,
    //     outsideUserContext: true, //! search 'outsideUserContext' and return this as well
    // },
    {
      title: "יצירת קשר",
      href: "https://wa.me/972587501168",
      external: true,
    },
    {
      title: "החשבון שלי",
      href: "/account",
    },
  ];

  const isPremiumUser = isPremium({
    type: user.userData?.accountType,
  });
  return (
    <div className="flex items-center">
      <button onClick={() => setOpenMenu(true)}>
        <Bars3Icon className={buttonStyle} />
      </button>
      <Drawer
        open={openMenu}
        setOpen={setOpenMenu}
        onClose={() => setOpenMenu(false)}
        onOpen={() => {}}
        anchor="right"
        className="min-h-screen w-[80vw] lg:w-[40vw] z-40 flex flex-col justify-between"
        // disableSwipeToOpen
      >
        <div>
          <div className="w-full pt-3 flex justify-between">
            <Link className="w-full" to="/" onClick={() => setOpenMenu(false)}>
              <Logo />
            </Link>
            <button
              type="button"
              onClick={() => setOpenMenu(false)}
              className="flex w-full justify-end p-1"
            >
              <XMarkIcon className="h-7 w-7 text-slate-800" />
            </button>
          </div>
          <div className="flex flex-col justify-between min-h-[75vh] overflow-y-auto no-scrollbar">
            <ul className="w-full flex flex-col justify-start gap-0 divide-y divide-slate-300 text-2xl">
              {routes.map(({ title, href, external }) => {
                // ({ title, href, outsideUserContext, external }) => {
                const isAllHebrewChars =
                  typeof title === "string" &&
                  /^[\u0590-\u05FF\s]+$/.test(title);

                return (
                  <li
                    key={title.toString()}
                    className={`${
                      isAllHebrewChars ? "" : "font-poppins"
                    } flex justify-between w-full items-center py-4`}
                  >
                    {external ? (
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-full"
                        onClick={() => setOpenMenu(false)}
                      >
                        {title}
                      </a>
                    ) : (
                      <Link
                        // outsideUserContext={
                        //     outsideUserContext
                        // }
                        onClick={() => setOpenMenu(false)}
                        to={href}
                        className="w-full"
                      >
                        {title}
                      </Link>
                    )}
                  </li>
                );
              })}
              <div className="w-full h-[55vh]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        as="div"
                        className="flex justify-between gap-2 py-4 cursor-pointer"
                      >
                        <div>
                          <h6 className="text-2xl">מחשבון מבצעים</h6>
                          {open ? (
                            <div className="text-slate-600 text-base">
                              גלו אם המבצע באמת משתלם
                            </div>
                          ) : null}
                        </div>
                        <ChevronDownIcon
                          className={`h-5 w-5 mt-0.5 transition-all ${
                            open ? "rotate-180" : ""
                          }`}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="mt-5 pb-5 max-w-[400px] lg:mx-auto">
                          <OffersCalculator />
                          {/* <label className="inline-flex items-center cursor-pointer mt-5">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              checked={keepCalcOpen}
                              onChange={() => {
                                setKeepCalcOpen((prev) => !prev);
                                localStorage.setItem(
                                  LOCAL_STORAGE_KEY_KEEP_CALC_OPEN,
                                  keepCalcOpen ? "false" : "true"
                                );
                              }}
                            />
                            <div className="relative w-6 h-3 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-green-600" />
                            <span
                              className={`ms-2 text-xs ${
                                keepCalcOpen ? "font-medium" : ""
                              } text-gray-900`}
                            >
                              השאר פתוח
                            </span>
                          </label> */}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            </ul>
            {isPremiumUser ? (
              <Link
                onClick={() => setOpenMenu(false)}
                to={`/premium-info/?phone=${user.userId}`}
                className="flex w-48 mx-auto items-center justify-center gap-2 text-lg mt-40 text-green-500  rounded-full py-1.5 px-2 text-center"
              >
                <span>
                  {isPremiumUser ? "משתמש " : "שדרג ל"} Plus{" "}
                  <BoltIcon className="h-5 w-5 inline-block text-amber-300" />
                </span>
              </Link>
            ) : null}
          </div>
        </div>
        {/* <div className="min-h-[400px] h-[450px] overflow-y-auto">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button
                  as="div"
                  className="flex items-center justify-between gap-2 py-4 cursor-pointer"
                >
                  <h6 className="text-2xl">מחשבון מבצעים</h6>
                  <ChevronDownIcon
                    className={`h-5 w-5 mt-0.5 transition-all ${
                      open ? "rotate-180" : ""
                    }`}
                    aria-hidden="true"
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="rounded-lg bg-slate-50/70 p-6 mt-2">
                  <OffersCalculator />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div> */}
        <Footer lean />
      </Drawer>
    </div>
  );
};

const OPEN_NEW_LIST_ACTION = "open-list-action";

const ListsDropdown = ({ isEditMode }: { isEditMode?: boolean }) => {
  const { listsData, loading, error } = useListsData();
  const { user } = useUser();
  const { listId } = useListIdFromParams();
  const [selected, setSelected] = useState(
    listId ?? user?.userData?.currentListId
  );
  const currentList = listsData?.find((list) => list.listId === selected);
  const navigate = useNavigate();

  if (!listsData || !currentList) return null;

  return (
    <Listbox
      value={selected}
      onChange={async (listId) => {
        if (!user?.userId) return;
        if (listId === OPEN_NEW_LIST_ACTION) {
          navigate(`/lists?new=1`);
        } else {
          navigate(`/list/${listId}${isEditMode ? "/edit" : ""}`);
          // updateUser({
          //     userId: user?.userId,
          //     updates: {
          //         currentListId: listId,
          //     },
          // });
          setSelected(listId);
        }
      }}
    >
      <div className="relative">
        <Listbox.Button className="flex items-center justify-between gap-2 relative cursor-default focus:outline-none sm:text-sm">
          <h6 className="text-[1.35rem] font-medium truncate">
            {currentList.name}
          </h6>
          <span className="pointer-events-none flex items-center">
            <ChevronUpDownIcon
              className="h-5 w-5 text-slate-300 mt-0.5"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 w-[200px] max-h-[60vh] overflow-auto rounded-md bg-white p-2 text-xl font-semibold shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {listsData.map((list, index) => (
              <Listbox.Option
                key={list.name + index}
                className={({ active, selected }) =>
                  `flex items-center justify-between rounded-lg gap-5 px-2.5 py-4 cursor-default select-none text-gray-900 ${
                    active || selected
                      ? "bg-green-50/80 text-green-900"
                      : "text-gray-900"
                  }`
                }
                value={list.listId}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`text-lg truncate ${
                        selected ? "font-bold" : "font-medium"
                      }`}
                    >
                      {index + 1}. {list.name}
                    </span>
                    {selected ? (
                      <span className="flex items-center text-green-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
            <Listbox.Option
              className="flex items-center justify-between rounded-lg gap-5 px-2.5 py-4 cursor-default select-none text-gray-900 text-lg font-normal"
              value={OPEN_NEW_LIST_ACTION}
            >
              פתח עוד רשימה
              <PlusIcon className="h-5 w-5 text-green-500" />
            </Listbox.Option>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
