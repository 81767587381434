// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyB0izLgnxHCJZ6Rj0sBMO9C74KwSUpy6ZY",
    authDomain: "groceries-bot-473f2.firebaseapp.com",
    projectId: "groceries-bot-473f2",
    storageBucket: "groceries-bot-473f2.appspot.com",
    messagingSenderId: "502836022418",
    appId: "1:502836022418:web:f49ceb77d2488f28582a4b",
    measurementId: "G-1WCZ463GK8",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// export const analytics = getAnalytics(app);
export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
const functions = getFunctions(app);

export const payWithSumit = httpsCallable(functions, "payWithSumit");
export const authenticateUser = httpsCallable(functions, "authenticate");
export const removeParticipantFromList = httpsCallable(
    functions,
    "removeParticipantFromList"
);
export const deleteListFromUser = httpsCallable(
    functions,
    "deleteListFromUser"
);
export const addItems = httpsCallable(functions, "addItems");
export const createNewSecondaryList = httpsCallable(functions, "createNewSecondaryList");

// connectFunctionsEmulator(functions, "localhost", 5001);

export const LISTS_COLLECTION = "lists-v2";
export const USERS_COLLECTION = "users-v2";
export const TXS_ERRORS_COLLECTION = "txs-errors";
