import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import { ADDED_FROM, type PreviousList } from "types";
import { ListNavbar } from "components/Navbar";
import ListCXT, { ComingSoon } from "context/ListContext";
import { useEffect, useMemo, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { getListData, updateList } from "db-utils";
import LoadingComponents from "components/Loader";
import { isEmpty } from "lodash";
import { Disclosure, Transition } from "@headlessui/react";
import useConfirm from "hooks/useConfirm";
import { Color } from "context/ConfirmContextProvider";
import MeltingClockIcon from "components/icons/MeltingClockIcon";
import useAlert from "hooks/useAlert";
import { Timestamp } from "firebase/firestore";
import useUser from "hooks/useUser";

export default function PreviousLists() {
  const { listId } = useListIdFromParams();
  const { listData, loading } = useListData({ listId });
  const { user } = useUser();

  if (loading) {
    return <LoadingComponents fullPage isList />;
  }

  if (!listData) {
    return (
      <div className="text-center mt-20">לא נמצאה רשימה עם המזהה המבוקש</div>
    );
  }

  //! add option to delete ? (but I want to collect it anyway)
  // ! add select several items to add
  // ! add save to saved-items
  // ! make prev-list item disclosure as well

  const { previousLists } = listData;

  const prevListsByMonth = isEmpty(previousLists)
    ? {}
    : Object.entries(previousLists).reduce<
        Record<string, { lists: PreviousList[]; sum: number | null }>
      >((acc, [key, value]) => {
        const date = value.purchaseTimestamp.toDate();
        const month = date.toLocaleString("he-IL", {
          month: "long",
        });
        const year = date.getFullYear();
        const monthKey = `${month} ${year}`;

        if (!acc[monthKey]) {
          acc[monthKey] = { lists: [], sum: null };
        }
        acc[monthKey].lists.push(value);
        if (value.cost) {
          if (acc[monthKey].sum === null) {
            acc[monthKey].sum = value.cost;
          } else {
            acc[monthKey].sum += value.cost;
          }
        }
        return acc;
      }, {} as Record<string, { lists: PreviousList[]; sum: number | null }>);

  const noListsToShow = isEmpty(prevListsByMonth);

  return (
    <ListCXT listId={listId}>
      <ListNavbar
        button={<span className="text-xl font-medium">- רשימות קודמות</span>}
      />
      {noListsToShow ? (
        <div className="text-lg text-center flex flex-col gap-4 items-center justify-center h-screen -mt-14 text-slate-800">
          <MeltingClockIcon className="w-28 h-28 mx-auto mb-4 text-green-500" />
          <h3 className="font-semibold text-xl">אין לנו מידע להציג לכם כאן</h3>
          <span>
            כדי שהרשימות הקודמות שלכם יופיעו כאן יש ללחוץ
            <span className="font-semibold mx-1">"סיימתי 🎉"</span>
            בסיום הקניה
          </span>
        </div>
      ) : (
        <div className="mt-20">
          <p className="text-slate-500 text-sm">
            כל הרשימות שסיימתם לקנות יפיעו כאן.
            <br /> לסיום קניה יש ללחוץ על "סיימתי"
          </p>
          <div className="flex items-center gap-2 my-4 text-sm text-slate-500">
            הגדרת תקציב חודשי, הוספה חלקית לרשימה, שמירה ל"שמורים" ועוד דברים
            נוחים...
            <ComingSoon />
          </div>
          <ul className="flex flex-col">
            {Object.entries(prevListsByMonth)
              .sort(
                ([aMonth, aLists], [bMonth, bLists]) =>
                  new Date(
                    bLists.lists[0].purchaseTimestamp.toDate()
                  ).getTime() -
                  new Date(aLists.lists[0].purchaseTimestamp.toDate()).getTime()
              )
              .map(([month, monthValue], index) => (
                <Disclosure key={month} defaultOpen={index === 0}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={`sticky top-20 text-xl font-bold  bg-white z-10 ${
                          open ? "h-16 sticky top-20" : "h-16"
                        }`}
                        onClick={() =>
                          open
                            ? window.scrollTo({ top: 0, behavior: "smooth" })
                            : null
                        }
                      >
                        <div className="w-full flex justify-between items-center h-full text-slate-700">
                          <h2>{month}</h2>
                          <div className="flex gap-2 items-center">
                            {monthValue.sum ? (
                              <span className="">
                                {monthValue.sum % 1 === 0
                                  ? monthValue.sum
                                  : monthValue.sum.toFixed(2)}{" "}
                                ₪
                              </span>
                            ) : null}
                            <ChevronDownIcon
                              className={`h-6 w-6  transition-all ${
                                open ? "rotate-180" : "rotate-0"
                              }`}
                            />
                          </div>
                        </div>
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel>
                          <ul className="flex flex-col gap-12 space-y-12 mt-5 mb-10">
                            {monthValue.lists
                              .sort(
                                (a: PreviousList, b: PreviousList) =>
                                  b.purchaseTimestamp.toDate().getTime() -
                                  a.purchaseTimestamp.toDate().getTime()
                              )
                              .map((list) => (
                                <PreviousListCard
                                  key={list.id}
                                  {...list}
                                  listId={listId}
                                  userId={user.userId}
                                />
                              ))}
                          </ul>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              ))}
          </ul>
        </div>
      )}
    </ListCXT>
  );
}

const PreviousListCard = ({
  items,
  purchaseTimestamp,
  cost,
  listId,
  id: purchaseId,
  userId,
}: PreviousList & {
  listId: string;
  userId: string | null;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [costInputValue, setCostInputValue] = useState<string>(
    cost?.toString() ?? ""
  );
  const boughtItems = useMemo(
    () => items.filter((item) => item.checked),
    [items]
  );
  // const unboughtItems = useMemo(
  //   () => items.filter((item) => !item.checked),
  //   [items]
  // );
  const showExpandButton = boughtItems.length > 8;

  const { isConfirmed } = useConfirm();
  const { isAlerted } = useAlert();

  const addAllItemsToList = async () => {
    if (
      await isConfirmed(
        "להוסיף את כל הפריטים מכאן לרשימה השבועית?",
        Color.success
      )
    ) {
      const currentList = await getListData({ listId });
      if (!currentList || !userId) {
        alert("קרתה תקלה, יש נסו שוב מאוחר יותר");
        return;
      }
      const { items: existingItems } = currentList;
      const existingItemsText = existingItems.map((item) => item.text);
      //! reset missing and checked for all added items.
      // ! if an item is checked | missing in existing list - I want to add it as not checked | missing
      const newItems = boughtItems
        .filter((item) => !existingItemsText.includes(item.text))
        .map((item) => ({
          ...item,
          checked: false,
          missing: false,
        }));

      const updatedExistingItems = existingItems.map((item) => {
        if (boughtItems.some((newItem) => newItem.text === item.text)) {
          return {
            ...item,
            checked: false,
            missing: false,
            addedAt: Timestamp.now(),
            addedBy: userId,
            addedFrom: ADDED_FROM.prev,
          };
        }
        return item;
      });
      const updatedItems = [...updatedExistingItems, ...newItems];
      await updateList({
        listId,
        updates: {
          items: updatedItems,
        },
      }).then(
        async (res) =>
          await isAlerted(res ? "הפריטים נוספו בהצלחה" : "קרתה תקלה, נסו שנית")
      );
    }
  };

  const updateListCost = () => {
    if (costInputValue === "") {
      return updateList({
        listId,
        updates: {
          [`previousLists.${purchaseId}.cost`]: null,
        },
      });
    }
    const newCost = parseFloat(costInputValue);

    if (isNaN(newCost)) {
      alert("הערך שהוזן לא תקין. יש לרשום ספרות בלבד");
      return false;
    }

    if (newCost !== cost) {
      return updateList({
        listId,
        updates: {
          [`previousLists.${purchaseId}.cost`]: newCost,
        },
      });
    }
  };

  useEffect(() => {
    if (cost) {
      setCostInputValue(cost.toString());
    }
  }, [cost]);

  return (
    <div
      className={`border-2 border-green-500/60 p-4 rounded-xl ${
        isExpanded ? "h-auto pb-10" : "h-[19.25rem]"
      } overflow-hidden relative shadow-sm w-full`}
    >
      <div className="flex flex-col gap-2 mb-4">
        <div className="flex justify-between items-center">
          <h3 className="font-bold text-xl flex items-center gap-3 space-x-3">
            {purchaseTimestamp.toDate().toLocaleDateString("he-IL", {
              day: "2-digit",
              month: "2-digit",
            })}{" "}
            <div className="w-[1.25px] h-[15px] mt-0.5 bg-slate-900" />
            <span>
              {boughtItems.length} {boughtItems.length === 1 ? "נקנה" : "נקנו"}
            </span>
          </h3>
          <div className="flex gap-1 items-center lg:w-28">
            <label htmlFor={`cost-${purchaseId}`}>עלות:</label>
            <div className="flex gap-0.5 w-10 lg:w-20">
              <input
                id={`cost-${purchaseId}`}
                type="text"
                pattern="^\d*(\.\d*)?$"
                value={costInputValue}
                placeholder=""
                onChange={(e) => setCostInputValue(e.target.value)}
                className="w-9 lg:w-12 focus:outline-none border-b border-slate-300 focus:border-slate-700 focus:border-b-[1.5px] rounded-none"
                onBlur={updateListCost}
              />{" "}
              ₪
            </div>
          </div>
        </div>
        <div className="flex gap-4 text-sm text-slate-500">
          <button
            className="underline underline-offset-2 font-medium text-green-600 disabled:text-slate-300"
            onClick={addAllItemsToList}
            disabled={boughtItems.length === 0}
          >
            הוסף הכל לרשימה
          </button>
          {/* <button className="underline">הוסף חלק לרשימה</button> */}
        </div>
      </div>
      <ul className="flex flex-col gap-1.5">
        {boughtItems.map((item, index) => {
          if (!isExpanded && index >= 7) return null;
          return (
            <li key={item.text}>
              {index + 1}. {item.text}
            </li>
          );
        })}
      </ul>
      {showExpandButton && (
        <div className="absolute bottom-0 left-0 right-0 h-20 flex items-end justify-center bg-gradient-to-t from-white to-transparent">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="mb-2 w-full flex items-center justify-center"
            // className="mb-2 px-4 py-1 rounded-full bg-green-500 text-white shadow-md hover:bg-green-600"
          >
            <ChevronDownIcon
              className={`h-6 w-6 text-green-500 ${
                isExpanded ? "rotate-180" : ""
              } transition-all`}
            />
          </button>
        </div>
      )}
    </div>
  );
};
