import { useAutoAnimate } from "@formkit/auto-animate/react";
import { UserPlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "components/Link";
import LoadingComponents, { Spinner } from "components/Loader";
import { ListNavbar } from "components/Navbar";
import { botConversationStarterLink } from "../../constants";
import ListCXT from "context/ListContext";
import { removeParticipantFromList } from "firebase.js";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useList from "hooks/useList";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useParticipants from "hooks/useParticipants";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useNavigate } from "react-router";
import { formatPhoneNumber } from "utils";

export default function ListParticipantsPage() {
    const { listId } = useListIdFromParams();
    const [parent] = useAutoAnimate();

    const { listData } = useListData({ listId });
    const { user } = useUser();
    const { participants, loading } = useParticipants({ listId });

    if (loading) return <LoadingComponents fullPage isList />;
    if (!listData || !participants) return null;

    const { userId } = user;
    const { created_by: createdBy } = listData;

    if (!userId) return null;

    const isUserInList = participants.some(
        (participant) => participant !== null && participant.userId === userId
    );

    if (!isUserInList)
        return (
            <div className="mt-20 text-center text-red-500">
                אתם לא נמצאים ברשימה הזו
            </div>
        );

    return (
        <ListCXT listId={listId}>
            <ListNavbar
                button={
                    <span className="text-xl font-medium">- ניהול משתתפים</span>
                }
            />
            <ul
                ref={parent}
                className="flex flex-col text-2xl mt-24 w-full py-5"
            >
                {participants.length > 0 ? (
                    participants.map((participant) =>
                        participant === null ? null : (
                            <li key={participant.userId}>
                                <ParticipantItem
                                    // name={participant.name}
                                    participantId={participant.userId}
                                    phoneNumber={participant.phoneNumber}
                                    isListOwner={userId === createdBy}
                                    userId={userId}
                                />
                            </li>
                        )
                    )
                ) : (
                    <div className="grid place-content-center text-2xl">
                        אין משתמשים להראות
                    </div>
                )}
                <Link
                    external
                    to={encodeURI(`${botConversationStarterLink}צירוף לרשימה`)}
                    className="flex text-slate-700 items-center justify-start gap-4 w-full text-lg py-5 px-1 border-t border-b font-medium"
                >
                    <UserPlusIcon className="w-7 h-7 ml-0.5" />
                    צירוף משתתף חדש לרשימה
                </Link>
            </ul>
        </ListCXT>
    );
}

const ParticipantItem = ({
    // name,
    participantId,
    phoneNumber,
    isListOwner,
    userId,
}: {
    // name: string;
    participantId: string;
    phoneNumber: string;
    isListOwner: boolean;
    userId: string;
}) => {
    const { listData } = useList();

    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    if (!listData) return null;

    const { id: listId, type: listType } = listData;

    const removeParticipant = async () => {
        if (
            await isConfirmed(
                `האם אתה בטוח שברצונך להסיר את ${formatPhoneNumber({
                    phoneNumber,
                })} מהרשימה?`
            )
        ) {
            setIsLoading(true);
            const { data } = (await removeParticipantFromList({
                listId,
                participantId,
                listType,
            })) as { data: { status: number; newListId?: string } };

            if (data?.status !== 200) {
                setIsLoading(false);
                await isAlerted("קרתה תקלה, אנא צרו איתנו קשר ונטפל בה");
                return;
            }
            const { newListId } = data;
            setIsLoading(false);
            if (newListId && userId === participantId) {
                navigate(`/list/${newListId}`);
            } else {
                window.location.reload(); // reload to update the list
            }
        }
    };

    const userIsCurrentParticipant = participantId === userId;
    const canRemoveHimself = userIsCurrentParticipant && !isListOwner; // if it's the owner primary list, block
    const canRemoveOthers = !userIsCurrentParticipant && isListOwner; // if owner and not current user, allow
    const canRemoveParticipant = canRemoveHimself || canRemoveOthers;

    return (
        <div className="flex gap-3 items-center justify-between w-full text-lg py-5 px-1 border-t">
            {formatPhoneNumber({ phoneNumber })}{" "}
            {/* {name} - {formatPhoneNumber({ phoneNumber })}{" "} */}
            {isListOwner && userIsCurrentParticipant ? "(בעלים)" : null}
            <button
                disabled={!canRemoveParticipant || isLoading}
                onClick={removeParticipant}
                className="group"
            >
                {isLoading ? (
                    <Spinner size="sm" />
                ) : (
                    <XMarkIcon className="h-7 w-7 text-red-500 group-disabled:text-slate-200" />
                )}
            </button>
        </div>
    );
};
