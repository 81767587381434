interface CookIconProps {
    className?: string;
}

const CookIcon: React.FC<CookIconProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        className={className}
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path
            fill="currentColor"
            stroke="currentColor"
            d="M39 46c-2.72 0-1 .4-1-2 0-1.94-2.71-5.06-3.26-5.67a1 1 0 0 0-1.48 0C32.71 38.94 30 42.06 30 44a3.91 3.91 0 0 0 .56 2h-3.12a3.91 3.91 0 0 0 .56-2c0-1.94-2.71-5.06-3.26-5.67a1 1 0 0 0-1.48 0C22.71 38.94 20 42.06 20 44a3.91 3.91 0 0 0 .56 2h-3.12a3.91 3.91 0 0 0 .56-2c0-1.94-2.71-5.06-3.26-5.67a1 1 0 0 0-1.48 0C12.71 38.94 10 42.06 10 44c0 2.39 1.71 2-1 2a1 1 0 0 0 0 2h30a1 1 0 0 0 0-2zm-7-2c0-.64.94-2.13 2-3.45 1.06 1.33 2 2.83 2 3.45a2 2 0 0 1-4 0zm-10 0c0-.64.94-2.13 2-3.45 1.06 1.33 2 2.83 2 3.45a2 2 0 0 1-4 0zm-10 0c0-.64.94-2.13 2-3.45 1.06 1.33 2 2.83 2 3.45a2 2 0 0 1-4 0zM43.91 14H4.09a4.1 4.1 0 0 0-1.83 7.75L6 23.62V27a9 9 0 0 0 9 9h18a9 9 0 0 0 9-9v-3.38l3.74-1.87A4.1 4.1 0 0 0 43.91 14zM3.16 20a2.09 2.09 0 0 1 .93-4H6v5.38zM40 27a7 7 0 0 1-7 7H15a7 7 0 0 1-7-7V16h32zm4.84-7L42 21.38V16h1.91a2.09 2.09 0 0 1 .93 4zM23.36 8.56C21.31 10.27 21.87 12 23 12a1 1 0 0 0 1-1c0-.92 2-1.21 2-3.33 0-2.67-3.38-2.56-1.36-4.24S26.14 0 25 0a1 1 0 0 0-1 1c0 .92-2 1.21-2 3.33s2 2.42 2 3.34c0 .33-.16.49-.64.89zM15.36 8.56C13.31 10.27 13.87 12 15 12a1 1 0 0 0 1-1c0-.92 2-1.21 2-3.33 0-2.67-3.38-2.56-1.36-4.24S18.14 0 17 0a1 1 0 0 0-1 1c0 .92-2 1.21-2 3.33s2 2.42 2 3.34c0 .33-.16.49-.64.89zM31.36 8.56C29.31 10.27 29.87 12 31 12a1 1 0 0 0 1-1c0-.92 2-1.21 2-3.33 0-2.67-3.38-2.56-1.36-4.24S34.14 0 33 0a1 1 0 0 0-1 1c0 .92-2 1.21-2 3.33s2 2.42 2 3.34c0 .33-.16.49-.64.89z"
        />
    </svg>
);

export default CookIcon;
