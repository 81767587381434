import React, { useState } from "react";
import ItemsCount from "./ItemsCount";
import OfferPrice from "./OfferPrice";
import PriceDetails from "./PriceDetails";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function OffersCalculator() {
  const [itemsCount, setItemsCount] = useState(2);
  const [offerPrice, setOfferPrice] = useState(25);
  const [originalPrice, setOriginalPrice] = useState("");

  return (
    <div className="flex flex-col gap-5 text-slate-900">
      <ItemsCount itemsCount={itemsCount} setItemsCount={setItemsCount} />
      <OfferPrice offerPrice={offerPrice} setOfferPrice={setOfferPrice} />
      <PriceDetails
        itemsCount={itemsCount}
        offerPrice={offerPrice}
        originalPrice={originalPrice}
        setOriginalPrice={setOriginalPrice}
      />
    </div>
  );
}

export const PlusMinusButtons = ({
  onPlusClick,
  onMinusClick,
  onCentsClick,
  minusDisabled,
}: {
  onPlusClick: () => void;
  onMinusClick: () => void;
  onCentsClick?: () => void;
  minusDisabled: boolean;
}) => {
  return (
    <div className="flex gap-2.5 font-poppins text-sm text-slate-500/80">
      <button className="" onClick={onPlusClick}>
        <PlusIcon className="w-3.5 h-3.5" />
      </button>
      <button
        className="disabled:text-slate-200"
        disabled={minusDisabled}
        onClick={onMinusClick}
      >
        <MinusIcon className="w-3.5 h-3.5" />
      </button>
      {onCentsClick ? (
        <button className="" onClick={onCentsClick}>
          0.90₪+
        </button>
      ) : null}
    </div>
  );
};
