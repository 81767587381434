import { Transition, Dialog } from "@headlessui/react";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { CheckBox } from "./list/ListItem";
import useList from "hooks/useList";
import { Item, PreviousList } from "types";
import { CameraIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { WhatsappIcon } from "./icons/WhatsappIcon";
import { ComingSoonBadge } from "context/ListContext";
import { Timestamp } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { updateList } from "db-utils";
import { Spinner } from "./Loader";
import Link from "./Link";
import { concatArrayItemsWithLimit } from "utils";
import { cloneDeep } from "lodash";
import { ListDataExtended } from "hooks/useListData";

export default function FinishedPopup({
  isOpen,
  onCancel,
  onConfirm,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const [values, setValues] = useState<{
    deleteChecked: boolean;
    returnMissing: boolean;
    cost: number | null;
  }>({
    deleteChecked: true,
    returnMissing: true,
    cost: null,
  });
  const [showSummary, setShowSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseId, setPurchaseId] = useState<string | null>(null);
  const [summaryValues, setSummaryValues] = useState<{
    cost: number | null;
    lastItems: Item[] | null;
    missingItems: Item[] | null;
    boughtItems: Item[] | null;
    unboughtItems: Item[] | null;
    items: Item[] | null;
  }>({
    cost: null,
    lastItems: null,
    missingItems: null,
    boughtItems: null,
    unboughtItems: null,
    items: null,
  });

  const { listData } = useList();

  const costInDB = useMemo(() => {
    if (!listData || !purchaseId) return null;
    const previousList = listData.previousLists?.[purchaseId];
    return previousList?.cost ?? null;
  }, [listData, purchaseId]);

  useEffect(() => {
    setSummaryValues((prev) => ({
      ...prev,
      cost: costInDB,
    }));
  }, [costInDB]);

  const { bought, missing, unbought, total, date } = useMemo(() => {
    if (!listData) {
      return {
        bought: 0,
        missing: 0,
        unbought: 0,
        total: 0,
        date: new Date(),
      };
    }
    return {
      bought: listData.items.filter((i) => i.checked).length,
      missing: listData.items.filter((i) => i.missing).length,
      unbought: listData.items.filter((i) => !i.checked && !i.missing).length,
      total: listData.items.length,
      date: new Date(),
    };
  }, [listData]);

  const onFinished = useCallback(async () => {
    if (!listData) {
      alert("קרתה תקלה, אנא נסה שוב מאוחר יותר");
      return;
    }
    setIsLoading(true);

    const newPurchaseId = uuid();
    const listSnapshotToSave: PreviousList = {
      id: newPurchaseId,
      purchaseTimestamp: Timestamp.now(),
      items: cloneDeep(listData.items),
      cost: values.cost ?? null,
    };

    setPurchaseId(newPurchaseId);
    setSummaryValues((prev) => ({
      ...prev,
      cost: values.cost,
      boughtItems: listSnapshotToSave.items.filter((i) => i.checked),
      missingItems: listSnapshotToSave.items.filter((i) => i.missing),
      unboughtItems: listSnapshotToSave.items.filter(
        (i) => !i.checked && !i.missing
      ),
      items: listSnapshotToSave.items,
    }));

    const updatedItems = listData!.items
      .map((item) => {
        const updatedItem = item;
        if (values.deleteChecked && item.checked) {
          return null;
        }
        if (values.returnMissing && item.missing) {
          updatedItem.missing = false;
        }
        return updatedItem;
      })
      .filter(Boolean) as Item[]; // filter out checked items

    const updates = {
      [`previousLists.${newPurchaseId}`]: listSnapshotToSave,
      items: updatedItems,
    };

    await updateList({ listId: listData.id, updates });
    setShowSummary(true);
    setIsLoading(false);
  }, [listData, values]);

  const onCloseSummary = () => {
    onConfirm();
    setShowSummary(false);
    setValues({
      deleteChecked: true,
      returnMissing: true,
      cost: null,
    });
    setSummaryValues({
      cost: null,
      lastItems: null,
      missingItems: null,
      boughtItems: null,
      unboughtItems: null,
      items: null,
    });
    setPurchaseId(null);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        dir="rtl"
        as="div"
        className="font-assistant relative z-50"
        onClose={showSummary ? onCloseSummary : onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full flex flex-col gap-7 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-start  align-middle shadow-xl transition-all">
                {isLoading ? (
                  <div className="flex items-center justify-center w-full h-64">
                    <Spinner size="lg" />
                  </div>
                ) : showSummary ? (
                  <Summary
                    date={date}
                    summaryValues={summaryValues}
                    costInDB={costInDB}
                    listData={listData}
                    purchaseId={purchaseId}
                    onCloseSummary={onCloseSummary}
                    setSummaryValues={setSummaryValues}
                  />
                ) : (
                  <FinishedConfirmation
                    bought={bought}
                    missing={missing}
                    unbought={unbought}
                    total={total}
                    values={values}
                    setValues={setValues}
                    onCancel={onCancel}
                    onFinished={onFinished}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

const FinishedConfirmation = ({
  bought,
  missing,
  unbought,
  total,
  onCancel,
  onFinished,
  setValues,
  values,
}: {
  bought: number;
  missing: number;
  unbought: number;
  total: number;
  onCancel: () => void;
  onFinished: () => void;
  setValues: React.Dispatch<
    React.SetStateAction<{
      deleteChecked: boolean;
      returnMissing: boolean;
      cost: number | null;
    }>
  >;
  values: {
    deleteChecked: boolean;
    returnMissing: boolean;
    cost: number | null;
  };
}) => (
  <>
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center justify-between w-full">
        <Dialog.Title
          as="h3"
          className={`text-xl"font-semibold leading-6 text-gray-900`}
        >
          סידור הרשימה ומעקב הוצאות
        </Dialog.Title>
        <button onClick={onCancel} className="focus:outline-none">
          <XMarkIcon className="w-5 h-5 text-slate-500 hover:text-slate-700" />
        </button>
      </div>

      <div className="flex gap-2 items-center text-slate-600">
        <div>
          {bought}/{total} {Number(bought) > 1 ? "נקנו" : "נקנה"}
        </div>
        <span>&bull;</span>
        <div>
          {missing} {Number(missing) > 1 ? "חסרים" : "חסר"}
        </div>
        <span>&bull;</span>
        <div>
          {unbought} {Number(unbought) > 1 ? "נשארו" : "נשאר"}
        </div>
      </div>
    </div>

    <>
      <div className="flex flex-col gap-2">
        <div
          className="flex items-center"
          onClick={() =>
            setValues((prev) => ({
              ...prev,
              deleteChecked: !prev.deleteChecked,
            }))
          }
        >
          <CheckBox checked={values.deleteChecked} />
          <label className="text-lg text-slate-700">מחק מסומנים</label>
        </div>
        <div
          className="flex items-center"
          onClick={() =>
            setValues((prev) => ({
              ...prev,
              returnMissing: !prev.returnMissing,
            }))
          }
        >
          <CheckBox
            disabled={missing === 0}
            checked={missing > 0 && values.returnMissing}
          />
          <label
            className={`text-lg ${
              missing === 0 ? "text-slate-300" : "text-slate-700"
            }`}
          >
            החזר חסרים
          </label>
        </div>
      </div>
      <div className="w-full flex justify-between items-center">
        <div className="w-full flex items-center gap-2">
          <label className="text-lg text-slate-700">הקנייה עלתה:</label>
          <input
            type="number"
            className="w-14 h-6 border-b border-slate-300 focus:outline-none focus:border-slate-600"
            placeholder="ככה וככה"
            value={values.cost ?? ""}
            onChange={(e) => {
              const cost = e.target.value;
              setValues((prev) => ({
                ...prev,
                cost: cost === "" ? null : Number(cost),
              }));
            }}
          />
          <span>₪</span>
        </div>
        <div className="relative flex flex-col gap-0.5 items-center">
          <ComingSoonBadge />
          <button
            disabled
            className="rounded-full border group border-slate-500 disabled:border-slate-300 p-2 hover:bg-slate-100"
          >
            <CameraIcon className="w-5 h-5 text-slate-500 hover:text-slate-700 group-disabled:text-slate-300" />
          </button>
          <span className="text-xs text-slate-500">קבלה</span>
        </div>
      </div>
    </>
    <div className="text-lg mt-6 flex items-center justify-between">
      <div className="w-full text-lg flex gap-3 justify-end items-center">
        <button
          type="button"
          className="inline-flex justify-center items-center rounded-md border border-transparent bg-white px-4 py-1.5 font-medium text-slate-600 hover:bg-opacitiy-50 focus:outline-none"
          onClick={onCancel}
        >
          ביטול
        </button>
        <button
          type="button"
          className={`btn-primary w-24 focus:outline-none`}
          onClick={onFinished}
        >
          סיימתי
        </button>
      </div>
    </div>
  </>
);

const Summary = ({
  date,
  summaryValues,
  onCloseSummary,
  setSummaryValues,
  costInDB,
  listData,
  purchaseId,
}: {
  date: Date;
  summaryValues: {
    cost: number | null;
    lastItems: Item[] | null;
    missingItems: Item[] | null;
    boughtItems: Item[] | null;
    unboughtItems: Item[] | null;
    items: Item[] | null;
  };
  onCloseSummary: () => void;
  setSummaryValues: React.Dispatch<
    React.SetStateAction<{
      cost: number | null;
      lastItems: Item[] | null;
      missingItems: Item[] | null;
      boughtItems: Item[] | null;
      unboughtItems: Item[] | null;
      items: Item[] | null;
    }>
  >;
  costInDB: number | null;
  listData?: ListDataExtended;
  purchaseId?: string | null;
}) => {
  const unboughtItemsText = useCallback(
    (limit: number = 3) =>
      !summaryValues.unboughtItems || summaryValues.unboughtItems.length === 0
        ? "0"
        : concatArrayItemsWithLimit({
            items: summaryValues.unboughtItems,
            limit: limit,
          }),
    [summaryValues.unboughtItems]
  );
  const summaryMissingItemsText = useMemo(
    () =>
      !summaryValues.missingItems || summaryValues.missingItems.length === 0
        ? "0"
        : concatArrayItemsWithLimit({
            items: summaryValues.missingItems,
            limit: 3,
          }),
    [summaryValues.missingItems]
  );

  const shareSummaryText = useMemo(() => {
    const { boughtItems, unboughtItems, missingItems, cost } = summaryValues;

    const header = `*קניות בסופר עם סאלי - ${date.toLocaleDateString("he-IL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })}*`;

    let body = `קניתי ${boughtItems?.length} פריטים`;
    if (cost !== null) {
      body += `\nזה עלה ${cost.toLocaleString()}₪`;
    }
    if (missingItems && missingItems.length > 0) {
      body += `\n\nהיה חסר: ${summaryMissingItemsText}`;
    }
    if (unboughtItems && unboughtItems.length > 0) {
      body += `\n\nלא קניתי: ${unboughtItemsText(5)}`;
    }

    return `${header}\n\n${body}`;
  }, [summaryValues, date, summaryMissingItemsText, unboughtItemsText]);

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <div className="flex items-center justify-between w-full">
          <Dialog.Title
            as="h3"
            className={`text-2xl font-semibold leading-6 text-gray-900`}
          >
            סיכום קנייה - $
            {date.toLocaleDateString("he-IL", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </Dialog.Title>
          <button onClick={onCloseSummary} className="focus:outline-none">
            <XMarkIcon className="w-5 h-5 text-slate-500 hover:text-slate-700" />
          </button>
        </div>
        <div className="flex gap-1 items-center">
          <CheckIcon className="w-4 h-4 text-green-600" />
          נשמר להיסטוריה
        </div>
      </div>
      <div className="flex flex-col gap-4 text-lg">
        <div className="flex gap-2">
          <span className="underline underline-offset-2 text-slate-700 font-medium w-[4.25rem] shrink-0">
            קניתי:
          </span>
          {summaryValues.boughtItems?.length === summaryValues.items?.length
            ? `הכל! 🥳 (${summaryValues.boughtItems?.length} פריטים)`
            : `${summaryValues.boughtItems?.length}/
        ${summaryValues.items?.length}`}
        </div>
        {summaryValues.missingItems?.length ? (
          <div className="flex gap-2">
            <span className="underline underline-offset-2 text-slate-700 font-medium w-[4.25rem] shrink-0">
              היה חסר:
            </span>
            <span>{summaryMissingItemsText}</span>
          </div>
        ) : null}
        {summaryValues.unboughtItems?.length ? (
          <div className="flex gap-1">
            <span className="underline underline-offset-2 text-slate-700 font-medium w-[4.25rem] shrink-0">
              לא קניתי:
            </span>
            <span>{unboughtItemsText()}</span>
          </div>
        ) : null}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!listData?.id) return;
            updateList({
              listId: listData.id,
              updates: {
                [`previousLists.${purchaseId}.cost`]: summaryValues.cost,
              },
            });
          }}
          className="flex items-center gap-2"
        >
          <label className="text-slate-700 font-medium">הקנייה עלתה:</label>
          <input
            type="number"
            className="w-14 h-6 border-b border-slate-300 focus:outline-none focus:border-slate-600"
            placeholder="לא הוזן"
            value={summaryValues.cost ?? ""}
            onChange={(e) => {
              const cost = e.target.value;
              setSummaryValues((prev) => ({
                ...prev,
                cost: cost === "" ? null : Number(cost),
              }));
            }}
          />
          <span>₪</span>
          {costInDB !== summaryValues.cost && (
            <div className="flex gap-2 ml-2">
              <button type="submit">
                <CheckIcon className="w-5 h-5 text-green-500" />
              </button>
              <button
                type="button"
                onClick={() =>
                  setSummaryValues((prev) => ({
                    ...prev,
                    cost: costInDB,
                  }))
                }
              >
                <XMarkIcon className="w-5 h-5 text-red-500" />
              </button>
            </div>
          )}
        </form>
      </div>
      <div className="text-lg mt-6 flex items-center justify-between">
        <div className="w-full text-lg flex gap-3 justify-end items-center">
          <Link
            className="btn-outline"
            to={`https://wa.me?text=${encodeURIComponent(shareSummaryText)}`}
            data-action="share/whatsapp/share"
            external
          >
            שתף עם איש קשר
            <WhatsappIcon className="w-4 h-4 ml-2" />
          </Link>

          <button
            type="button"
            className={`text-slate-700 px-2 focus:outline-none`}
            onClick={onCloseSummary}
          >
            סגירה
          </button>
        </div>
      </div>
    </>
  );
};
