import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { getCategoryById, sortItemsByCategory } from "categories";
import LoadingComponents, { Spinner } from "components/Loader";
import { ListNavbar } from "components/Navbar";
// import AddItemPopup from "components/list/AddItemPopup";
import ListItem from "components/list/ListItem";
import ListCXT from "context/ListContext";
// import { useFirebaseRoutesAnalytics } from "hooks/useFirebaseAnalytics";
import useListData from "hooks/useListData";
import useListIdFromParams from "hooks/useListIdFromParams";
import useUser from "hooks/useUser";
import ReactConfetti from "react-confetti";
import { useSearchParams } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Item } from "types";
import { EditDrawer } from "./edit";
// import useIsIphoneAndPWA from "hooks/useIsIphoneAndPWA";
import useDebounce from "hooks/useDebounce";
import {
  ArrowLeftIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { addItemToList } from "db-utils";
import useAlert from "hooks/useAlert";
import { similarityScore } from "utils";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

export default function ListPage() {
  const { listId } = useListIdFromParams();
  const [searchParams] = useSearchParams();
  const showInSplitMode = searchParams.get("split");
  const { user, loading: userLoading } = useUser();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [itemIdToEdit, setItemIdToEdit] = useState<string | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [isFocused, setIsFocused] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);

  // useFirebaseRoutesAnalytics({ listId });
  const { listData, loading, error } = useListData({ listId });

  const [listToShow, setListToShow] = useState<Array<Item>>();
  const [isListToShowLoading, setListToShowLoading] = useState(true);
  const {
    items: listInDB,
    categoriesSortOrder,
    splitMode,
    partialListsConfig,
  } = listData ?? {};

  const [parent] = useAutoAnimate({
    duration: !listToShow
      ? 400
      : listToShow.length <= 15
      ? 400
      : listToShow.length <= 30
      ? 600
      : 1300,
  });
  const userId = user?.userId;

  useEffect(() => {
    if (!listInDB) return;

    const sortedList = sortItemsByCategory({
      items: listInDB,
      categoriesSortOrder,
    });

    const listWithMissingAtBottom = [...sortedList].sort(
      (firstItem: Item, secondItem: Item) => {
        const { missing: a } = firstItem;
        const { missing: b } = secondItem;
        if (a && !b) return 1;
        if (!a && b) return -1;
        return 0;
      }
    );

    const listWithMarkedAtBottom = [...listWithMissingAtBottom].sort(
      (firstItem: Item, secondItem: Item) => {
        const { checked: a } = firstItem;
        const { checked: b } = secondItem;
        if (a && !b) return 1;
        if (!a && b) return -1;
        return 0;
      }
    );

    const searchMatchThreshold = debouncedSearchTerm.length <= 3 ? 0.8 : 0.6;

    const filteredList = !debouncedSearchTerm
      ? listWithMarkedAtBottom // no search, show all
      : debouncedSearchTerm.includes(",")
      ? [] // user is adding items
      : listWithMarkedAtBottom
          .map((item) => {
            const textScore = similarityScore(item.text, debouncedSearchTerm);
            let categoryScore = 0;
            if (sortedList.length < 50) {
              categoryScore = similarityScore(
                getCategoryById({ id: item.categoryId })?.title || "",
                debouncedSearchTerm
              );
            }
            return {
              ...item,
              matchScore: Math.max(textScore, categoryScore),
            };
          })
          .filter((item) => item.matchScore > searchMatchThreshold)
          .sort((a, b) => b.matchScore - a.matchScore);

    const showSplitMode = partialListsConfig && splitMode && showInSplitMode;

    if (showSplitMode) {
      const partialList = partialListsConfig?.find((partialList) =>
        partialList.assignees.includes(userId!)
      );

      const currentPartList = filteredList.filter(
        (item) => partialList?.categories.includes(item.categoryId) ?? false
      );

      setListToShow(currentPartList);
    } else {
      setListToShow(filteredList);
    }
    setListToShowLoading(false);
  }, [
    listInDB,
    categoriesSortOrder,
    partialListsConfig,
    splitMode,
    showInSplitMode,
    userId,
    debouncedSearchTerm,
  ]);

  const listProgress = useMemo(
    () =>
      listInDB
        ? listInDB.filter((item) => item.checked || item.missing).length /
          listInDB.length
        : 0,
    [listInDB]
  );

  const { isAlerted } = useAlert();

  const onAddItemSubmit = useCallback(
    async (
      e: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLButtonElement>
    ) => {
      e.preventDefault();
      try {
        if (!listData || !listInDB || !user || !user.userId) return;
        const { items: existingItems } = listData;
        const { userData } = user;
        e.preventDefault();
        setIsAddingItem(true);
        const value = searchTerm.trim();
        if (!value) {
          await isAlerted("שם הפריט לא יכול להיות ריק");
          return;
        }
        const isNewTextNumeric = !isNaN(Number(value));
        if (isNewTextNumeric) {
          await isAlerted("שם הפריט לא יכול להיות ספרה בלבד");
          return;
        }
        const isNewTextListWord = value === "רשימה";
        if (isNewTextListWord) {
          await isAlerted('שם הפריט לא יכול להיות "רשימה"');
          return;
        }
        if (
          existingItems.some(
            (item) => item.text === value && !item.checked && !item.missing
          )
        ) {
          await isAlerted("פריט עם שם זהה כבר קיים ברשימה");
          return;
        }

        const succeeded = await addItemToList({
          itemsText: value,
          listId,
          userId: user.userId,
          token: userData.token,
        });
        if (!succeeded) {
          await isAlerted("אירעה שגיאה בהוספת הפריט");
        } else {
          setSearchTerm("");
        }
      } catch (error) {
        console.error(error);
        await isAlerted("אירעה שגיאה בהוספת הפריט");
      } finally {
        setIsAddingItem(false);
        // window.scrollTo(0, 0);
      }
    },
    [searchTerm, listId, listData, listInDB, user, isAlerted]
  );

  //   useEffect(() => {
  //     if(listProgress<1 && clickedFinished) {
  //       setShowConfetti(true);
  //     }
  //   }, [listProgress,clickedFinished]);

  // const isIphoneAndPWA = useIsIphoneAndPWA();

  if (loading || userLoading) {
    return <LoadingComponents fullPage isList />;
  }

  if (error || !listData || !listInDB) {
    return (
      <div className="flex items-center justify-center flex-col gap-5 mt-16">
        <h1 className="text-3xl font-medium">לא נמצאה רשימה</h1>
        <p className="text-lg">יש להיכנס דרך הקישור שקיבלתם בוואטסאפ</p>
        <div>
          <p>
            הבעיה נמשכת?{" "}
            <a
              className="underline"
              href="https://wa.me/972587501168"
              target="_blank"
              rel="noopener noreferrer"
            >
              דברו איתנו
            </a>
          </p>
        </div>
      </div>
    );
  }

  if (!userId) {
    return (
      <div className="flex pt-20 mt-16 flex-col gap-5 items-center justify-center h-full text-center text-3xl">
        <h1>לא נמצא משתמש</h1>
        <div className="text-xl">יש להיכנס דרך הקישור שקיבלתם בוואטסאפ</div>
      </div>
    );
  }

  if (isListToShowLoading) {
    return <LoadingComponents fullPage isList />;
  }

  return (
    <ListCXT listId={listId}>
      <EditDrawer
        {...{ openEditDrawer, setOpenEditDrawer }}
        itemId={itemIdToEdit}
      />
      <div className="h-full relative mt-16 no-select">
        {showConfetti ? (
          <ReactConfetti
            gravity={0.13}
            initialVelocityY={11}
            numberOfPieces={800}
            recycle={false}
            opacity={0.8}
            onConfettiComplete={() => setShowConfetti(false)}
            tweenDuration={12000}
          />
        ) : null}
        <div className="relative w-full">
          <ListNavbar
            listProgress={listProgress}
            showSplitMode={Boolean(showInSplitMode)}
            hasFinished={listProgress === 1}
            isListPage
          />
          {/* <div
                    className={`fixed ${
                        isIphoneAndPWA ? "bottom-28" : "bottom-20"
                    } right-0 pr-4 pl-2 z-20 w-full`}
                >
                    <AddItemPopup />
                </div> */}
          <form
            onSubmit={onAddItemSubmit}
            className="fixed z-10 w-full right-0 px-3 pb-3 lg:px-60 bg-white top-0 mt-20 pt-2 flex gap-2.5 items-center"
          >
            {searchTerm.length > 0 ? (
              <div className="flex items-center gap-2 absolute left-16 lg:left-[19.5rem] top-2 h-10 text-slate-300">
                <button
                  disabled={!searchTerm || isAddingItem}
                  type="button"
                  onClick={() => setSearchTerm("")}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
                {searchTerm.length > 2 && (listToShow?.length ?? 0) > 0 ? (
                  <div className="flex items-center gap-2">
                    להוספה <ArrowLeftIcon className="w-4 h-4 mt-0.5" />
                  </div>
                ) : null}
              </div>
            ) : null}
            <input
              type="text"
              placeholder={
                isFocused ? 'לדוגמה: "חלב, 3 ביצים, ..."' : "הוספה וחיפוש"
              }
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              disabled={isAddingItem}
              className={`w-full ${
                isFocused
                  ? "placeholder:text-slate-400 border-slate-500/80 border-[1.5px]"
                  : "placeholder:text-slate-300/75 border-slate-200/60"
              } placeholder:text-lg h-10 text-xl p-2 border rounded-full focus:outline-none`}
            />
            {isAddingItem ? (
              <div className="w-10">
                <Spinner size="sm" />
              </div>
            ) : (
              <button
                className={`group rounded-full transition-all flex items-center justify-center ${
                  searchTerm.length > 1 ? "bg-green-500 w-9 h-9 shrink-0" : ""
                }`}
                type="submit"
                disabled={!searchTerm || isAddingItem}
              >
                {searchTerm.length >= 2 && listToShow?.length === 0 ? (
                  <PaperAirplaneIcon className="rotate-180 w-5 h-5 text-white" />
                ) : (
                  <PlusIcon
                    className={`${
                      searchTerm.length >= 2
                        ? "text-white w-7 h-7"
                        : "text-green-500 w-9 h-9"
                    } ${
                      isFocused
                        ? "group-disabled:text-slate-300"
                        : "group-disabled:text-slate-100"
                    } transition-all`}
                  />
                )}
              </button>
            )}
          </form>
        </div>
        <ul
          ref={parent}
          className="flex flex-col divide-y divide-slate-100 mt-36 text-2xl w-full pb-20"
        >
          {listToShow && listToShow.length ? (
            listToShow.map((listItem) => (
              <ListItem
                key={listItem.text}
                {...listItem}
                onEditClick={() => {
                  setItemIdToEdit(listItem.text);
                  setOpenEditDrawer(true);
                }}
                setShowConfetti={setShowConfetti}
                onItemLongPress={() => {
                  setItemIdToEdit(listItem.text);
                  setOpenEditDrawer(true);
                }}
              />
            ))
          ) : (
            <div className="text-xl mt-2">
              {searchTerm ? (
                <div className="text-slate-600 flex flex-col">
                  <div className="flex items-center">
                    לחצו על
                    <button
                      disabled={!searchTerm || isAddingItem}
                      onClick={onAddItemSubmit}
                      className="mx-2 inline-flex items-center justify-center bg-green-500 rounded-full p-1 w-7 h-7"
                    >
                      <PaperAirplaneIcon className="rotate-180 mr-0.5 w-4 h-4 inline-block text-white" />
                    </button>
                    כדי להוסיף לרשימה:
                  </div>
                  <span className="mt-1 mx-1 text-start text-slate-800 font-semibold">
                    {searchTerm
                      .split(",")
                      .filter((item) => item.trim() !== "")
                      .reverse()
                      .map((item, index) => (
                        <span key={item + index}>
                          {index + 1}. {item.trim()}
                          <br />
                        </span>
                      ))}
                  </span>
                </div>
              ) : (
                <div className="text-slate-600 text-center text-xl mt-2">
                  אין פריטים להציג
                </div>
              )}
            </div>
          )}
        </ul>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={2}
        closeButton={() => null}
        transition={Bounce}
        draggablePercent={33}
        style={{ bottom: "64px" }} // 64px for the bottom navbar
        className="md:!w-1/2"
      />
    </ListCXT>
  );
}
