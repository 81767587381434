import { ArrowDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useMemo } from "react";

interface PriceDetailsProps {
  itemsCount: number;
  offerPrice: number;
  originalPrice: string;
  setOriginalPrice: React.Dispatch<React.SetStateAction<string>>;
}

export default function PriceDetails({
  itemsCount,
  offerPrice,
  originalPrice,
  setOriginalPrice,
}: PriceDetailsProps) {
  const pricePerItem = useMemo(
    () => offerPrice / itemsCount || 0,
    [offerPrice, itemsCount]
  );

  useEffect(() => {
    if (!originalPrice) {
      setOriginalPrice((Number(pricePerItem) + 1.5).toString());
    }
  }, [originalPrice, pricePerItem, setOriginalPrice]);

  const discountInPercent =
    (originalPrice
      ? ((pricePerItem - Number(originalPrice)) / Number(originalPrice)) * 100
      : 0) * -1; // -1 to make it positive

  const itemDiscount = originalPrice ? Number(originalPrice) - pricePerItem : 0;
  const totalDiscount = originalPrice
    ? (Number(originalPrice) - pricePerItem) * itemsCount
    : 0;

  return (
    <div className="flex flex-col justify-between gap-5 w-full pt-5 border-t">
      <div className="flex flex-col gap-2">
        <div className="font-semibold text-xl w-full flex gap-2">
          {/* <div className="font-semibold text-xl w-full bg-white rounded-lg p-2"> */}
          <div className="w-28">מחיר ליחידה:</div>
          <div className="font-poppins font-medium text-xl flex">
            <div className="w-16 text-center font-semibold">
              {Number.isInteger(pricePerItem)
                ? pricePerItem
                : pricePerItem.toFixed(2)}
            </div>
            ₪
          </div>
        </div>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="font-semibold text-lg w-full flex gap-2 text-slate-600"
        >
          <label htmlFor="originalPrice" className="w-28">
            מחיר מקורי:
          </label>
          <span className="font-poppins font-medium">
            <input
              id="originalPrice"
              type="number"
              className="rounded-none w-16 text-center bg-transparent border-b border-slate-300 focus:outline-none focus:border-black"
              placeholder=""
              value={originalPrice}
              onChange={(e) => setOriginalPrice(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.currentTarget.blur();
                }
              }}
              onBlur={(e) =>
                e.currentTarget.parentElement?.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
            />
            ₪
          </span>
        </form>
      </div>
      {originalPrice && (
        <div className="text-sm text-slate-700 flex justify-between items-start">
          <div className="">הנחה של: </div>
          <div className="flex flex-col font-poppins" dir="ltr">
            ₪{Number(itemDiscount) % 1 ? itemDiscount.toFixed(1) : itemDiscount}{" "}
            <span className="font-assistant text-slate-400 text-[0.6rem] leading-[0.5rem] text-center">
              ליח'
            </span>
          </div>
          &bull;
          <div
            dir="ltr"
            className={`font-poppins ${
              totalDiscount >= 5
                ? "text-green-600"
                : totalDiscount < 2
                ? "text-red-600"
                : ""
            }`}
          >
            ₪
            {Number(totalDiscount) % 1
              ? totalDiscount.toFixed(1)
              : totalDiscount}
          </div>
          &bull;
          <div
            dir="ltr"
            className={`font-poppins  ${
              discountInPercent >= 8
                ? "text-green-600"
                : discountInPercent < 2
                ? "text-red-600"
                : ""
            }`}
          >
            <ArrowDownIcon
              className={`mr-0.5 h-3 w-3 inline-block transition-all ${
                discountInPercent < 0 ? "rotate-180" : ""
              }`}
            />
            {discountInPercent.toFixed(1)}%
          </div>
        </div>
      )}
    </div>
  );
}
